import classnames from 'classnames';
import Link from 'next/link';

import { dataTypes } from '../../../utils/propTypes';
import classes from '../Pagination.module.scss';

import { ARROW_DIRECTIONS } from './DirectionArrow.constants';

const DirectionArrow = ({ show = true, direction = ARROW_DIRECTIONS.LEFT, url = '' }) => {
  const relValue = direction === ARROW_DIRECTIONS.LEFT ? 'prev' : 'next';

  const elementClasses = [
    classnames(classes.Pagination__listAnchor, {
      [classes.isLeftArrow]: direction === ARROW_DIRECTIONS.LEFT,
      [classes.isRightArrow]: direction === ARROW_DIRECTIONS.RIGHT,
    }),
  ].join(' ');

  return show ? (
    <li className={classes.Pagination__listElement}>
      <Link href={url} legacyBehavior>
        <a className={elementClasses} rel={relValue}>
          {relValue}
        </a>
      </Link>
    </li>
  ) : null;
};

DirectionArrow.propTypes = dataTypes.directionArrow.props;
DirectionArrow.defaultProps = dataTypes.directionArrow.default;

export default DirectionArrow;
