import type { Article } from '~/types/models';

import { getArticleUrl } from '~/utils/urls';

import classes from './BlogArticleList.module.scss';

interface BlogArticleListProps {
  label: string;
  posts: Article[];
  readMoreVisible?: boolean;
}

const BlogArticleList = ({ posts, label, readMoreVisible }: BlogArticleListProps) => {
  const renderBlogPostAnchor = (singlePost: Article) => (
    <li key={singlePost.id} className={classes.BlogArticleList__listElement}>
      <a className={classes.BlogArticleList__listElementAnchor} href={getArticleUrl(singlePost)}>
        {singlePost.title}
      </a>
    </li>
  );

  if (!posts?.length) {
    return null;
  }

  return (
    <ul className={classes.BlogArticleList__listContainer}>
      {label && (
        <li className={classes.BlogArticleList__listElement}>
          <strong className={classes.BlogArticleList__listElementLabel}>{label}</strong>
        </li>
      )}
      {posts.map(renderBlogPostAnchor)}
      {readMoreVisible && (
        <li className={classes.BlogArticleList__listElement}>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/blog/" className={classes.BlogArticleList__listElementAnchor}>
            See all articles <span className={classes.BlogArticleList__readMore} />
          </a>
        </li>
      )}
    </ul>
  );
};

export default BlogArticleList;
