import classnames from 'classnames';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import urlcat from 'urlcat';
import * as yup from 'yup';

import BottomSmallPerks from '~/components/BottomSmallPerks/BottomSmallPerks';
import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import {
  BUTTON_BG_COLORS,
  BUTTON_SIZES,
} from '~/components/Buttons/RoundedButton/RoundedButton.constants';
import { trackRedirect } from '~/lib/mixpanel/mixpanel';

import classes from './SignupForm.module.scss';

const formInputsSchema = yup.object().shape({
  email: yup
    .string('This field must be a string.')
    .email('This field must contain a valid email.')
    .required('This field can not be empty.'),
});

const handleSubmitAction = async (values, actions) => {
  actions.validateForm(values);
  const targetUrl = urlcat(
    `${values.domain}?source_url=:domainName&source_id=hero-input&email=:encodedEmail`,
    {
      domainName: values.domain,
      encodedEmail: values.email,
    },
  );
  trackRedirect(targetUrl, 'sign_up_email_filled');
};

const SignupForm = ({
  buttonWithBorder,
  settings,
  buttonText,
  buttonUrl,
  perks,
  className,
  elementClassnameSignUp,
}) => (
  <Formik
    initialValues={{
      email: '',
      domain: buttonUrl ?? settings.pageDomain,
    }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={formInputsSchema}
    onSubmit={handleSubmitAction}
  >
    {() => (
      <div className={classnames(classes.SignupForm, className)}>
        <Form className={classes.SignupForm__inner} noValidate>
          <div className={classes.SignupForm__inputContainer}>
            <Field
              name="email"
              type="email"
              placeholder="Your E-mail"
              aria-label="Your E-mail"
              className={classes.SignupForm__emailInput}
            />
            <ErrorMessage
              name="email"
              className={classes.SignupForm__errorMessage}
              component="div"
            />
          </div>
          <div className={classes.SignupForm__buttonContainer}>
            <RoundedButton
              type="submit"
              content={{
                label: buttonText,
                withBorder: buttonWithBorder,
                elementClassname: classnames(
                  elementClassnameSignUp,
                  classes.SignupForm__buttonInput,
                ),
                size: BUTTON_SIZES.REGULAR,
                backgroundColor: BUTTON_BG_COLORS.LIGHT_PURPLE,
              }}
            />
          </div>
        </Form>
        <BottomSmallPerks
          className={classes.SignupForm__smallPerksContainer}
          data={perks.trim().split(',')}
        />
      </div>
    )}
  </Formik>
);

export default SignupForm;
