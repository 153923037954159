import help from '~/public/assets/icons/help.svg';

import classes from './Help.module.scss';

interface HelpProps {
  children: React.ReactNode;
  content?: string;
}

const Help = ({ children, content }: HelpProps) => {
  if (!content) {
    return children;
  }

  return (
    <div className={classes.container}>
      {children}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img className={classes.icon} src={help.src} alt={content} />
      <div className={classes.tooltip}>{content}</div>
    </div>
  );
};

export default Help;
