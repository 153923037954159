import classNames from 'classnames';
import range from 'lodash/range';
import Image from 'next/image';

import type { G2Widget as G2WidgetProps } from '~/types/models';

import logo from '~/public/assets/icons/g2-logo.svg';
import star from '~/public/assets/icons/g2-star.svg';

import classes from './G2Widget.module.scss';

const reviewsHref = 'https://www.g2.com/products/bugbug/reviews';
export const G2Widget = ({ content: { variant } }: { content: G2WidgetProps }) => {
  if (variant === 'row') {
    return (
      <a href={reviewsHref} target="_blank" rel="noreferrer" className={classes.rowContainer}>
        <Image src={logo.src} alt="G2 Logo" height={20} width={20} />
        <div className={classes.starsContainer}>
          {range(0, 5).map((i) => (
            <Image key={`${i}-g2-star`} src={star.src} alt="Star" width={18} height={18} />
          ))}
        </div>
        <p className={classes.ratingParagraph}>
          <span className={classes.rating}>4.9</span> / 5
        </p>
      </a>
    );
  }

  return (
    <a href={reviewsHref} target="_blank" rel="noreferrer" className={classes.columnContainer}>
      <Image src={logo.src} alt="G2 Logo" height={36} width={36} className={classes.logo} />
      <div className={classes.starsContainer}>
        {range(0, 5).map((i) => (
          <Image key={`${i}-g2-star`} src={star.src} alt="Star" width={22} height={22} />
        ))}
      </div>
      <p className={classNames(classes.ratingParagraph, classes.rating)}>See all G2 reviews</p>
    </a>
  );
};
