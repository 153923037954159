import { useContext } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';
import { AuthorsList } from '~/types/models';
import { getAuthorUrl } from '~/utils/urls';

import LinkList from '../LinkList/LinkList';

interface AuthorsListProps {
  content: AuthorsList;
}

const AuthorsList = ({ content }: AuthorsListProps) => {
  const { authors: fullAuthorsList = [] } = useContext(SharedDataContext);
  const { title } = content;

  const authorsList = fullAuthorsList
    .filter(({ articles }) => Boolean(articles?.length))
    .map((author) => ({
      name: author.name,
      url: getAuthorUrl({ ...author, slug: author.slug || '/' }),
    }));

  return <LinkList title={title} data={authorsList} />;
};

export default AuthorsList;
