import classnames from 'classnames';

import { SectionTitle } from '~/types/models';
import { createMarkup } from '~/utils/functions';

import classes from './SectionTitle.module.scss';

interface SectionTitleProps {
  content: SectionTitle;
  className?: string;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const SectionTitle = ({ content, className, headingSize = 'h2' }: SectionTitleProps) => {
  const elementBackgroundClasses = classnames(className, classes.SectionTitle, [
    classes[content.backgroundColor ?? 'Transparent'],
  ]);

  const Heading = headingSize;

  return (
    <section className={elementBackgroundClasses}>
      <div className={classes.SectionTitle__inner}>
        {/* eslint-disable-next-line react/no-danger */}
        <Heading
          className={classes.SectionTitle__text}
          dangerouslySetInnerHTML={createMarkup(content.title, true)}
        />
      </div>
    </section>
  );
};

export default SectionTitle;
