import type { SectionBackground } from '~/types/models';

import classes from './BaseSection.module.scss';

export interface BaseSectionProps {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  background: SectionBackground;
  withMarginBottom?: boolean;
}

const BaseSection = ({
  className,
  contentClassName,
  children,
  background,
  withMarginBottom = true,
}: BaseSectionProps) => {
  const containerClassNames = [
    classes.baseSection,
    classes[`${background}Background`],
    withMarginBottom ? '' : classes.noBottomMargin,
    className,
  ].join(' ');

  const contentClassNames = [classes.baseSection__content, contentClassName].join(' ');

  return (
    <section className={containerClassNames}>
      <div className={classes.baseSection__inner}>
        <div className={contentClassNames}>{children}</div>
      </div>
    </section>
  );
};

export default BaseSection;
