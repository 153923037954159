import { useContext } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';
import { RecentArticlesList } from '~/types/models';

import SidebarRecentArticle from '../Content/Blog/Appetizers/SidebarRecentArticle/SidebarRecentArticle';
import SidebarTitle from '../SidebarTitle';

import classes from './RecentArticlesList.module.scss';

interface RecentArticlesListProps {
  content: RecentArticlesList;
}

const RecentArticlesList = ({ content }: RecentArticlesListProps) => {
  const { recentPosts = [] } = useContext(SharedDataContext);
  const { title } = content;

  return (
    <div className={classes.container}>
      {title && <SidebarTitle content={{ title }} />}
      <div className={classes.list}>
        {recentPosts.map((article) => (
          <SidebarRecentArticle data={article} key={article.id} />
        ))}
      </div>
    </div>
  );
};

export default RecentArticlesList;
