import classNames from 'classnames';
import Image from 'next/image';

import type { StickyBar as StickyBarContent } from '~/types/models';

import closeIcon from '~/public/assets/icons/x.svg';

import PageTemplateProvider from '../PageTemplateProvider/PageTemplateProvider';

import { StickyBarProvider, useParentStickyBar } from './StickyBar.context';
import classes from './StickyBar.module.scss';

interface StickyBarProps {
  bar: StickyBarContent;
}

export const StickyBar = (props: StickyBarProps) => {
  const { bar } = props;
  return (
    <StickyBarProvider id={bar.id}>
      <StickyBarRenderer {...props} />
    </StickyBarProvider>
  );
};

const StickyBarRenderer = ({ bar }: StickyBarProps) => {
  const containerClassNames = classNames(
    classes.stickyBar,
    classes[bar.background],
    !bar.showOnMobile && classes.disableOnMobile,
    !bar.showOnDesktop && classes.disableOnDesktop,
  );

  const { isVisible, setIsVisible } = useParentStickyBar();

  const onClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className={containerClassNames}
      style={{
        height: `${bar.maxHeight}px`,
      }}
    >
      <div className={classes.content}>
        <PageTemplateProvider pageLayout={bar.content} />
      </div>
      <button type="button" className={classes.close} onClick={onClose}>
        <Image src={closeIcon.src} alt="Close popup" width={24} height={24} />
      </button>
    </div>
  );
};
