import type { BubbleTestimonialSection, CaseStudy } from '~/types/models';

import { getCaseStudyUrl } from '~/utils/urls';

import Testimonial from '../../Testimonial/Testimonial';

interface CaseStudyTestimonialProps {
  content: CaseStudy;
}

const CaseStudyTestimonial = ({ content }: CaseStudyTestimonialProps) => {
  const testimonialSection = content.content.find(
    // eslint-disable-next-line no-underscore-dangle
    (section) => section.__component === 'primitives.testimonial',
  ) as BubbleTestimonialSection;

  if (!testimonialSection) {
    return null;
  }

  return (
    <Testimonial
      content={{
        testimonial: testimonialSection.testimonial,
        variant: 'bubble',
        sectionBackground: 'LightGrayPurple',
        withMarginBottom: false,
        buttonLabel: 'Read the Case Study',
        buttonUrl: getCaseStudyUrl(content),
      }}
    />
  );
};

export default CaseStudyTestimonial;
