import type { ComponentType } from 'react';
import type { PageComponentType } from '~/types/models';

import ArticleList from '../ArticlesList';
import AuthorsList from '../AuthorsList';
import Banner from '../Banner';
import RoundedButton from '../Buttons/RoundedButton';
import CategoriesList from '../CategoriesList';
import { ActiveCampaignForm } from '../Content/ActiveCampaignForm/ActiveCampaignForm';
import ServicePerks from '../Content/Frontpage/ServicePerks/ServicePerks';
import { HeroCTASection } from '../Content/HeroCTASection/HeroCTASection';
import HeroWithForm from '../Content/HeroWithForm/HeroWithForm';
import AboutUsPictures from '../Content/Page/AboutUsPictures/AboutUsPictures';
import BookDemo from '../Content/Page/BookDemo/BookDemo';
import CaseStudyTestimonial from '../Content/Page/CaseStudyTestimonial/CaseStudyTestimonial';
import Contact from '../Content/Page/Contact/Contact';
import ContentTitle from '../Content/Page/ContentTitle';
import IntegrationsList from '../Content/Page/IntegrationsList';
import JobOffersList from '../Content/Page/JobOffersList/JobOffersList';
import PricingPlans from '../Content/Page/PricingPlans/PricingPlans';
import SectionTitle from '../Content/Page/SectionTitle/SectionTitle';
import SlideShow from '../Content/Page/SlideShow/SlideShow';
import SolutionsSection from '../Content/Page/SolutionsSection/SolutionsSection';
import { StickyBarHeader } from '../Content/StickyBarHeader/StickyBarHeader';
import Testimonial from '../Content/Testimonial/Testimonial';
import TextWithImageSection from '../Content/TextWithImageSection/TextWithImageSection';
import ToolsComparison from '../Content/ToolsComparison/ToolsComparison';
import ExtraSpace from '../ExtraSpace';
import { G2Widget } from '../G2Widget/G2Widget';
import PopupTitle from '../PopupTitle/PopupTitle';
import RecentArticlesList from '../RecentArticlesList';
import SidebarTitle from '../SidebarTitle';
import TableCompareSection from '../TableCompareSection';

export const COMPONENT_BY_SECTION: Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<PageComponentType, ComponentType<any>>
> = {
  'page-components.page-opinion-slider': SlideShow,
  'page-components.page-section-title': SectionTitle,
  'frontpage-components.frontpage-perks': ServicePerks,
  'primitives.testimonial': Testimonial,
  'page-components.page-book-a-demo': BookDemo,
  'page-components.page-about-us': AboutUsPictures,
  'page-components.page-contact': Contact,
  'page-components.page-service-pricing': PricingPlans,
  'page-components.job-offers-list': JobOffersList,
  'page-components.single-case-study': CaseStudyTestimonial,
  'page-components.solutions': SolutionsSection,
  'page-components.page-hero-with-form': HeroWithForm,
  'page-components.tools-comparison': ToolsComparison,
  'page-components.page-half-image-description': TextWithImageSection,
  'page-components.table-section': TableCompareSection,
  'page-components.integrations': IntegrationsList,
  'page-components.extra-space': ExtraSpace,
  'page-components.content-title': ContentTitle,
  'page-components.sidebar-headline': SidebarTitle,
  'primitives.rounded-button': RoundedButton,
  'page-components.banner': Banner,
  'page-components.all-categories-list': CategoriesList,
  'page-components.all-authors-list': AuthorsList,
  'page-components.articles-list': ArticleList,
  'page-components.recent-articles': RecentArticlesList,
  'page-components.hero-cta-section': HeroCTASection,
  'page-components.active-campaign-form': ActiveCampaignForm,
  'page-components.g2-widget': G2Widget,

  // Popup
  'popup-components.popup-title': PopupTitle,

  // Sticky bar
  'sticky-components.sticky-bar-header': StickyBarHeader,

  // Deprecated components
  'frontpage-components.frontpage-section-title': SectionTitle,
  'frontpage-components.frontpage-description-with-image': TextWithImageSection,
};
