import { ActiveCampaignFormRenderer } from '~/components/Content/ActiveCampaignForm/ActiveCampaignForm';
import { useTrackFormSubmit } from '~/lib/mixpanel/mixpanel.hooks';
import { createMarkup } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import ClientsLogosList from '../ClientsLogosList/ClientsLogosList';
import BookDemoForm from '../Forms/BookDemoForm/BookDemoForm';

import classes from './BookDemo.module.scss';

const BookDemo = ({ content }) => {
  const renderForm = () =>
    content?.bookDemoForm ? (
      <ActiveCampaignFormRenderer formData={content.bookDemoForm} />
    ) : (
      <BookDemoForm />
    );

  const formContainerRef = useTrackFormSubmit('book demo');

  return (
    <section className={classes.BookDemo}>
      <div className={classes.BookDemo__inner}>
        <div className={classes.BookDemo__content}>
          <h2 className={classes.BookDemo__title}>{content.title}</h2>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={createMarkup(content.content)} />
          <ClientsLogosList clients={content.collabBrands} />
        </div>
        <div className={classes.BookDemo__form} ref={formContainerRef}>
          {content.showForm ? renderForm() : null}
        </div>
      </div>
    </section>
  );
};

BookDemo.propTypes = {
  content: dataTypes.bookDemo.props,
};
BookDemo.defaultProps = {
  content: dataTypes.bookDemo.default,
};

export default BookDemo;
