import PropTypes from 'prop-types';

import { TYPE_OF_AUTHORS, NUMBER_OF_COLUMNS, BACK_BUTTON_DATA, EMPTY_LIST_TEXT } from './constants';

const genericReactComponent = {
  props: PropTypes.element.isRequired,
  default: {},
};

const genericImage = {
  props: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    alternativeText: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  default: {
    name: '',
    url: '',
    alternativeText: '',
    height: 1,
    width: 1,
  },
};

const genericButton = {
  props: PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    withBorder: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    icon: genericImage.props,
  }),
  default: {
    icon: genericImage.default,
  },
};

const genericComponent = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      __component: PropTypes.string,
      id: PropTypes.number,
      created_at: PropTypes.string,
    }),
  ),
  default: [
    {
      __component: '',
      id: 0,
      created_at: '',
    },
  ],
};

const genericContent = {
  props: PropTypes.shape({
    content: genericComponent.props,
    seo: genericComponent.props,
  }),
  default: {
    content: genericComponent.default,
    seo: genericComponent.default,
  },
};

const mobileMenuData = {
  props: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        __component: PropTypes.string,
        label: PropTypes.string,
        url: PropTypes.string,
        elementClassname: PropTypes.string,
        page: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    ),
  }),
  default: {
    list: [
      {
        __component: '',
        label: '',
        url: null,
        elementClassname: '',
        page: null,
      },
    ],
  },
};

const globalSettings = {
  props: PropTypes.shape({
    postsPerPage: PropTypes.number,
    globalSEO: genericComponent.props,
    cookieLawSentence: PropTypes.string,
    pageDomain: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    extraComponents: PropTypes.array,
    contactEmail: PropTypes.string,
  }),
  default: {
    postsPerPage: 1,
    globalSEO: genericComponent.default,
    cookieLawSentence: '',
    pageDomain: '',
    extraComponents: [],
    contactEmail: '',
  },
};

const mainMenuData = {
  props: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        __component: PropTypes.string,
        url: PropTypes.string,
        label: PropTypes.string,
        elementClassname: PropTypes.string,
        page: PropTypes.shape({
          slug: PropTypes.string,
        }),
      }),
    ),
  }),
  default: {
    list: [
      {
        id: 0,
        __component: '',
        label: '',
        url: null,
        elementClassname: '',
        page: null,
      },
    ],
  },
};

const faqQuestion = {
  props: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
  default: {
    id: 0,
    question: '',
    answer: '',
  },
};

const faqData = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      groupLabel: PropTypes.string,
      faqQuestion: PropTypes.arrayOf(faqQuestion.props),
    }),
  ),
  default: [
    {
      groupLabel: '',
      faqQuestion: [faqQuestion.default],
    },
  ],
};

const caseStudiesData = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      excerpt: PropTypes.string,
      slug: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  ),
  default: [
    {
      title: '',
      content: '',
      excerpt: '',
      slug: '',
      image: {
        url: '',
      },
    },
  ],
};

const solutionsData = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      pageContent: PropTypes.string,
    }),
  ),
  default: [
    {
      title: '',
      content: '',
    },
  ],
};

const integrationsData = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }).isRequired,
      comingSoon: PropTypes.bool,
    }),
  ),
  default: [
    {
      name: '',
      description: '',
      comingSoon: false,
      slug: '',
      image: {
        url: '',
      },
    },
  ],
};

const sidebarCta = {
  props: PropTypes.shape({
    title: PropTypes.string,
    button: PropTypes.shape({
      label: PropTypes.string,
      slug: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  default: {
    title: '',
    button: {
      label: '',
      slug: '',
      type: '',
    },
  },
};

const postsCategories = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  default: [
    {
      name: '',
      slug: '',
    },
  ],
};

const recentPosts = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  default: [
    {
      excerpt: '',
      slug: '',
      title: '',
      content: [{}],
    },
  ],
};

const blogPosts = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      excerpt: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  default: [
    {
      slug: '',
      title: '',
      excerpt: '',
      content: [{}],
    },
  ],
};

const categoryData = {
  props: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    seo: genericComponent.props,
  }),
  default: {
    id: 0,
    name: '',
    seo: genericComponent.default,
  },
};

const blogPostsCount = {
  props: PropTypes.number,
  default: 0,
};

const paged = {
  props: PropTypes.number,
  default: 1,
};

const categoryPostsCount = {
  props: PropTypes.number,
  default: 0,
};

const currentPost = {
  props: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    seo: genericComponent.props,
  }),
  default: {
    title: '',
    name: '',
    slug: '',
    seo: genericComponent.default,
  },
};

const pageContent = {
  props: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        pageContent: genericComponent.props,
        seo: genericComponent.props,
      }),
    ),
    PropTypes.shape({
      pageContent: genericComponent.props,
      seo: genericComponent.props,
    }),
  ]),
  default: [
    {
      content: genericComponent.default,
      seo: genericComponent.default,
    },
  ],
};

const cta = {
  props: PropTypes.shape({
    label: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    withBorder: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    icon: genericImage.props,
  }),
  default: {
    icon: genericImage.default,
  },
};

const pagination = {
  props: PropTypes.shape({
    postCount: PropTypes.number,
    paged: PropTypes.number,
    postPerPage: PropTypes.number,
  }),
  default: {
    postCount: 0,
    postPerPage: 0,
    paged: 1,
  },
};

const directionArrow = {
  props: {
    show: PropTypes.bool.isRequired,
    direction: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  },
  default: {},
};

const paginationArrow = {
  props: {
    show: PropTypes.bool.isRequired,
    current: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  },
  default: {
    current: false,
    disabled: false,
  },
};

const ctaComponent = {
  props: PropTypes.shape({
    title: PropTypes.string,
    button: genericButton.props,
  }),
  default: {
    title: '',
    button: genericButton.default,
  },
};

const frontpageHero = {
  props: PropTypes.shape({
    kicker: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    heroImage: genericImage.props,
    buttonText: PropTypes.string,
    playButtonText: PropTypes.string,
    bottomSmallPerks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ),
    newsletterForm: PropTypes.bool,
    videoURL: PropTypes.string,
    elementClassnameVideo: PropTypes.string,
    elementClassnameSignUp: PropTypes.string,
  }),
  default: {
    kicker: '',
    title: '',
    subtitle: '',
    heroImage: genericImage.default,
    buttonText: '',
    playButtonText: 'Play video',
    bottomSmallPerks: [
      {
        label: '',
      },
    ],
    newsletterForm: false,
    videoURL: '',
    elementClassnameVideo: '',
    elementClassnameSignUp: '',
  },
};

const servicePerks = {
  props: PropTypes.shape({
    perkCollection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        icon: genericImage.props,
      }),
    ),
  }),
  default: {
    perkCollection: [
      {
        id: 0,
        title: '',
        description: '',
        icon: genericImage.default,
      },
    ],
  },
};

const postListArticle = {
  props: PropTypes.shape({
    publishDate: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    featuredImage: genericImage.props,
    article_authors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        image: genericImage.props,
      }),
    ),
  }),
  default: {
    publishDate: '',
    title: '',
    slug: '',
    featuredImage: genericImage.default,
    article_authors: [],
  },
};

const sidebarRecentArticle = {
  props: PropTypes.shape({
    publishDate: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string,
    featuredImage: genericImage.props,
  }),
  default: {
    publishDate: '',
    title: '',
    slug: '',
    featuredImage: genericImage.default,
  },
};

const article = {
  props: PropTypes.shape({
    __component: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    publishDate: PropTypes.string,
    featuredImage: genericImage.props,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      }),
    ),
    article_authors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        image: genericImage.props,
      }),
    ),
  }),
  default: {
    __component: '',
    id: 0,
    slug: '',
    title: '',
    body: '',
    publishDate: '',
    featuredImage: genericImage.default,
    content: {
      body: '',
    },
    article_authors: [],
  },
};

const caseStudy = {
  props: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    slug: PropTypes.string,
    content: PropTypes.string,
    quotes: PropTypes.shape({
      id: PropTypes.number,
      quote: PropTypes.string,
    }),
  }),
  default: {
    id: 0,
    title: '',
    slug: '',
    content: '',
    quotes: [],
  },
};

const blogHead = {
  props: {
    title: PropTypes.string,
    kicker: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  },
  default: {
    title: '',
    kicker: {
      url: '',
      label: '',
    },
  },
};

const newsletterSection = {
  props: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  default: {
    title: '',
    subtitle: '',
  },
};

const aboutUsPictures = {
  props: PropTypes.shape({
    id: PropTypes.number,
    singlePerson: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        role: PropTypes.string,
        picture: genericImage.props,
      }),
    ),
  }),
  default: {
    id: 0,
    singlePerson: [
      {
        id: 0,
        name: '',
        role: '',
        picture: genericImage.default,
      },
    ],
  },
};

const collabBrands = {
  props: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        asset: genericImage.props,
      }),
    ),
    title: PropTypes.string,
  }),
  default: {
    data: [
      {
        id: 0,
        asset: genericImage.default,
      },
    ],
    title: '',
  },
};

const bookDemo = {
  props: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    showForm: PropTypes.bool,
    collabBrands: collabBrands.props,
    bookDemoForm: PropTypes.string,
  }),
  default: {
    title: '',
    content: '',
    showForm: false,
    collabBrands: collabBrands.default,
    bookDemoForm: '',
  },
};

const contact = {
  props: PropTypes.shape({
    id: PropTypes.number,
    contactForm: PropTypes.string,
    contactSection: PropTypes.string,
    showForm: PropTypes.bool,
  }),
  default: {
    id: 0,
    contactForm: '',
    contactSection: '',
    showForm: false,
  },
};

const ctaSection = {
  props: PropTypes.shape({
    id: PropTypes.number,
    buttons: PropTypes.arrayOf(genericButton.props),
  }),
  default: {
    id: 0,
    buttons: [genericButton.default],
  },
};

const faqSection = {
  props: {
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        groupLabel: PropTypes.string,
        faqQuestion: PropTypes.arrayOf(faqQuestion.props),
      }),
    ),
    options: PropTypes.shape({
      __component: PropTypes.string,
      id: PropTypes.number,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          groupLabel: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
    }),
  },
  default: {
    content: [
      {
        id: 0,
        groupLabel: '',
        faqQuestion: [faqQuestion.default],
      },
    ],
    options: {
      __component: '',
      id: 0,
      questions: [
        {
          groupLabel: '',
          id: 0,
        },
      ],
    },
  },
};

const fullWidthSection = {
  props: {
    content: PropTypes.string,
    sectionDescription: PropTypes.string,
    sectionColor: PropTypes.string,
    cta: PropTypes.arrayOf(genericButton.props),
    bottomMargin: PropTypes.bool,
  },
  default: {
    content: '',
    sectionDescription: '',
    sectionColor: '',
    cta: [genericButton.default],
    bottomMargin: true,
  },
};

const halfImageSection = {
  props: {
    picture: genericImage.props,
    cta: genericButton.props,
    sectionDirection: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    sectionBackground: PropTypes.string,
  },
  default: {
    picture: genericImage.default,
    cta: genericButton.default,
    sectionDirection: '',
    title: '',
    description: '',
    sectionBackground: '',
  },
};

const pageHero = {
  props: {
    data: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      cta: genericButton.props,
      heroImage: genericImage.props,
    }),
  },
  default: {
    data: {
      title: '',
      description: '',
      cta: genericButton.default,
      heroImage: genericImage.default,
    },
  },
};

const perksTable = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: genericImage.props,
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  default: [
    {
      id: 0,
      icon: genericImage.default,
      title: '',
      description: '',
    },
  ],
};

const pricingPlans = {
  props: PropTypes.shape({
    plans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        smallDescription: PropTypes.string,
        button: genericButton.props,
        extraPerks: PropTypes.arrayOf(
          PropTypes.shape({
            element: PropTypes.string,
            id: PropTypes.number,
            tooltip: PropTypes.string,
          }),
        ),
        icon: genericImage.props,
        mainPerks: PropTypes.arrayOf(
          PropTypes.shape({
            content: PropTypes.string,
            id: PropTypes.number,
          }),
        ),
        popular: PropTypes.bool,
        pricing: PropTypes.arrayOf(
          PropTypes.shape({
            annotaton: PropTypes.string,
            currency: PropTypes.string,
            id: PropTypes.number,
            price: PropTypes.number,
            priceType: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  default: {
    plans: [
      {
        id: 0,
        title: '',
        subtitle: '',
        smallDescription: '',
        button: genericButton.default,
        extraPerks: [
          {
            element: '',
            id: 0,
            tooltip: '',
          },
        ],
        icon: genericImage.default,
        mainPerks: [
          {
            content: '',
            id: 0,
          },
        ],
        popular: false,
        pricing: [
          {
            annotaton: '',
            currency: '',
            id: 0,
            price: 0,
            priceType: '',
          },
        ],
      },
    ],
  },
};

const sectionTitle = {
  props: PropTypes.shape({
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
  default: {
    title: '',
    backgroundColor: '',
  },
};

const content = {
  props: { content: PropTypes.string, type: PropTypes.string },
  default: {
    content: '',
    type: 'Full',
  },
};

const simpleTitleContent = {
  props: {
    kicker: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
  },
  default: {
    kicker: '',
    title: '',
    subtitle: '',
  },
};

const slideShow = {
  props: PropTypes.shape({
    singleOpinion: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        personName: PropTypes.string,
        personRole: PropTypes.string,
        personScore: PropTypes.number,
        personSentence: PropTypes.string,
      }),
    ),
  }),
  default: {
    singleOpinion: [
      {
        id: 0,
        personName: '',
        personRole: '',
        personScore: 0,
        personSentence: '',
      },
    ],
  },
};

const menuItemElement = {
  props: {
    children: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isLabel: PropTypes.bool.isRequired,
    numberId: PropTypes.number,
  },
  default: {
    numberId: 0,
  },
};

const mainPageFrame = {
  props: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.object]),
  default: {},
};

const placeholderImage = {
  props: {
    cn: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    layout: PropTypes.oneOf(['fixed', 'intrinsic', 'responsive', 'fill']),
  },
  default: {
    cn: '',
    width: '',
    height: '',
    layout: 'intrinsic',
  },
};

const menuCTAsButtons = {
  props: PropTypes.shape({
    singleCTAButton: PropTypes.arrayOf(genericButton.props),
  }),
  default: {
    singleCTAButton: [genericButton.default],
  },
};

const quotaSection = {
  props: {
    quote: PropTypes.string,
    name: PropTypes.string,
  },
  default: {
    quote: '',
    name: '',
  },
};

const buttonWithBorder = {
  props: PropTypes.bool,
  default: false,
};

const blogPagedContent = {
  props: PropTypes.arrayOf(genericContent.props),
  default: [genericContent.default],
};

const closeMobileMenu = {
  props: PropTypes.func,
  default: () => {},
};

const jobOffersList = {
  props: PropTypes.shape({
    id: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    offers: PropTypes.array,
  }),
  default: {
    id: 0,
    offers: [],
  },
};

const singleJobOffer = {
  props: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.string,
    salaryMin: PropTypes.number,
    salaryMax: PropTypes.number,
    description: PropTypes.string,
    slug: PropTypes.string,
  }),
  default: {
    title: '',
    location: '',
    salaryMin: 0,
    salaryMax: 0,
    description: '',
    slug: '',
  },
};

const signupForm = {
  props: {
    buttonText: PropTypes.string,
    bottomSmallPerks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ),
    className: PropTypes.string,
    elementClassnameSignUp: PropTypes.string,
  },
  default: {
    buttonText: '',
    bottomSmallPerks: [],
    className: '',
    elementClassnameSignUp: '',
  },
};

const asideSection = {
  props: PropTypes.element.isRequired,
  default: null,
};

const authorsSectionType = {
  props: PropTypes.oneOf(Object.values(TYPE_OF_AUTHORS)),
  default: TYPE_OF_AUTHORS.STANDARD,
};

const postsAuthors = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      image: genericImage.props,
    }),
  ),
  default: [],
};

const postListNumberOfColumns = {
  props: PropTypes.oneOf(Object.values(NUMBER_OF_COLUMNS)),
  default: NUMBER_OF_COLUMNS.TWO,
};

const quotes = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      author: PropTypes.string,
      quote: PropTypes.string,
    }),
  ),
  default: [],
};

const video = {
  props: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    alternativeText: PropTypes.string,
    hash: PropTypes.string,
    ext: PropTypes.string,
    mime: PropTypes.string,
    url: PropTypes.string,
  }),
  default: {
    id: 0,
    name: '',
    alternativeText: '',
    hash: '',
    ext: '',
    mime: '',
    url: '',
  },
};

const postsDate = {
  props: PropTypes.string,
  default: '',
};

const backButtonData = {
  props: PropTypes.shape({
    path: PropTypes.string,
    page: PropTypes.string,
  }),
  default: {
    ...BACK_BUTTON_DATA.BLOG,
  },
};

const emptyListText = {
  props: PropTypes.string,
  default: EMPTY_LIST_TEXT.ARTICLES,
};

const elementClassname = {
  props: PropTypes.string,
  default: '',
};

const authors = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  default: [],
};

const testimonials = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  default: [],
};

const recentFooterPosts = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  default: [
    {
      title: '',
      slug: '',
    },
  ],
};

const footerMenuData = {
  props: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        __component: PropTypes.string,
        menuItems: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
            elementClassname: PropTypes.string,
            page: PropTypes.shape({
              slug: PropTypes.string,
            }),
          }),
        ),
      }),
    ),
  }),
  default: {
    list: [
      {
        __component: '',
        menuItems: [
          {
            label: '',
            url: null,
            elementClassname: '',
            page: null,
          },
        ],
      },
    ],
  },
};

const mostPopularPosts = {
  props: PropTypes.arrayOf(article.props),
  default: [],
};

const footerData = {
  props: PropTypes.shape({
    menu: footerMenuData.props,
    recentPosts: recentFooterPosts.props,
    mostPopularPosts: mostPopularPosts.props,
  }),
  default: {
    menu: footerMenuData.default,
    recentPosts: recentFooterPosts.default,
    mostPopularPosts: mostPopularPosts.default,
  },
};

export const dataTypes = {
  mobileMenuData,
  globalSettings,
  recentFooterPosts,
  footerMenuData,
  mainMenuData,
  faqData,
  caseStudiesData,
  solutionsData,
  integrationsData,
  sidebarCta,
  postsCategories,
  recentPosts,
  blogPosts,
  categoryData,
  blogPostsCount,
  paged,
  categoryPostsCount,
  currentPost,
  genericContent,
  genericComponent,
  pageContent,
  cta,
  pagination,
  directionArrow,
  paginationArrow,
  ctaComponent,
  frontpageHero,
  servicePerks,
  authors,
  testimonials,
  postListArticle,
  sidebarRecentArticle,
  article,
  blogHead,
  genericReactComponent,
  newsletterSection,
  aboutUsPictures,
  bookDemo,
  collabBrands,
  contact,
  ctaSection,
  faqSection,
  fullWidthSection,
  halfImageSection,
  pageHero,
  perksTable,
  pricingPlans,
  sectionTitle,
  content,
  simpleTitleContent,
  slideShow,
  menuItemElement,
  mainPageFrame,
  genericImage,
  placeholderImage,
  menuCTAsButtons,
  quotaSection,
  buttonWithBorder,
  blogPagedContent,
  closeMobileMenu,
  jobOffersList,
  singleJobOffer,
  signupForm,
  asideSection,
  authorsSectionType,
  postsAuthors,
  postListNumberOfColumns,
  caseStudy,
  quotes,
  video,
  postsDate,
  backButtonData,
  emptyListText,
  elementClassname,
  footerData,
};
