import classNames from 'classnames';

import type { Article, ArticlesList } from '~/types/models';
import type { PageData } from '~/types/page';

import PostListArticle from '../Content/Blog/Appetizers/PostListArticle/PostListArticle';
import EmptyContent from '../Content/Blog/EmptyContent/EmptyContent';
import SectionTitle from '../Content/Page/SectionTitle/SectionTitle';
import Pagination from '../Pagination/Pagination';

import classes from './ArticlesList.module.scss';

interface ArticleListProps {
  content: ArticlesList;
  pageData?: PageData;
}

const ArticleList = ({ content, pageData }: ArticleListProps) => {
  const { articlesPagination, articles: defaultArticles = [], hasSidebar } = pageData ?? {};
  const { articles: selectedArticles, articleCategoryHidden, emptyListText } = content;
  const articles = selectedArticles.length ? selectedArticles : defaultArticles;
  const columns = hasSidebar ? 'two' : 'three';

  const containerClassNames = classNames(
    classes.container,
    classes[content.background ?? 'Transparent'],
    classes[content.width ?? 'full'],
  );

  const renderArticle = (article: Article) => {
    const { id } = article;
    return (
      <PostListArticle
        data={article}
        key={id}
        columns={columns}
        categoryHidden={articleCategoryHidden}
      />
    );
  };

  return (
    <>
      {content.title && (
        <SectionTitle
          headingSize="h2"
          className={classes.title}
          content={{
            title: content.title,
            backgroundColor: content.background,
          }}
        />
      )}
      <div className={containerClassNames}>
        {articles.length ? (
          articles.map(renderArticle)
        ) : (
          <EmptyContent emptyListText={emptyListText} />
        )}
      </div>
      {articlesPagination && <Pagination pagination={articlesPagination} />}
    </>
  );
};

export default ArticleList;
