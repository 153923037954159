import classNames from 'classnames';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import Image from '~/components/Image/Image';
import { TextWithImageSection } from '~/types/models';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';

import classes from './TextWithImageSection.module.scss';

interface TextWithImageSectionProps {
  content: TextWithImageSection;
}

const TextWithImageSection = ({ content }: TextWithImageSectionProps) => {
  const {
    picture,
    sectionDirection,
    showPictureShadow,
    sectionCTAButton,
    sectionContent,
    sectionTitle,
    sectionBackground,
    fontSize = 'regular',
    withMarginBottom = true,
  } = content;
  const imageClassName = [
    classes.imagePart,
    classes[sectionDirection],
    showPictureShadow ? classes['imagePart--with-shadow'] : '',
  ];

  const containerClassNames = [
    classes.container,
    classes[sectionBackground],
    classes[`${content.textAlign}Align`],
    withMarginBottom ? classes.withMarginBottom : '',
  ].join(' ');

  const paragraphClassNames = classNames(classes.paragraph, classes[fontSize]);
  const titleClassNames = classNames(classes.title, classes[fontSize]);

  return (
    <section className={containerClassNames}>
      <div className={classes.inner}>
        <div className={`${classes.contentPart} ${classes[sectionDirection]}`}>
          {content.kicker && <span className={classes.kicker}>{content.kicker}</span>}
          <h3 className={titleClassNames}>{sectionTitle}</h3>
          <div
            className={paragraphClassNames}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(sectionContent)}
          />
          {sectionCTAButton && (
            <div className={classes.ctaHolder}>
              <RoundedButton content={sectionCTAButton} />
            </div>
          )}
        </div>
        <div className={imageClassName.join(' ')}>
          {picture ? (
            <Image
              src={getUploadAssetsURL(picture.url)}
              width={picture.width}
              height={picture.height}
              alt={picture.alternativeText}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default TextWithImageSection;
