import classnames from 'classnames';
import * as R from 'ramda';

import { trackLink } from '~/lib/mixpanel/mixpanel';
import { getUrlProps, isUnknownMouseClick, prepareURL } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import Link from '../../Link/Link';

import classes from './Menu.module.scss';

const COMPONENT_TYPE = {
  FOOTER_MENU_COLUMN: 'menu-components.footer-link-column',
};

const MenuItemElement = ({
  children,
  url,
  isLabel,
  numberId,
  isNativeLink,
  elementClassname,
  elementId,
  onClick,
  onAuxClick,
  openInNewTab,
}) => {
  const labelClassnames = [
    classnames(classes.listElementLabel, {
      [classes.withTopMargin]: numberId,
    }),
  ].join(' ');

  if (isLabel) {
    return <strong className={labelClassnames}>{children}</strong>;
  }

  const linkClassnames = [classnames(classes.listElementAnchor, elementClassname)].join(' ');

  return (
    <Link
      href={url}
      prefetch={false}
      id={elementId}
      className={linkClassnames}
      native={isNativeLink}
      onClick={onClick}
      onAuxClick={onAuxClick}
      {...getUrlProps({ url, openInNewTab })}
    >
      {children}
    </Link>
  );
};

const FooterMenu = ({ menu }) => {
  const renderSingleMenuElement = (singleMenuItem, index) => {
    const linkURL = singleMenuItem?.url || singleMenuItem?.page?.slug || '/';

    const handleClick = (mouseEvent) => {
      if (isUnknownMouseClick(mouseEvent)) return;

      const mixpanelEvent = singleMenuItem.event;
      if (mixpanelEvent) {
        trackLink(mouseEvent, mixpanelEvent.name, {
          placement: mixpanelEvent.placement,
          ...(mixpanelEvent.name === 'featurebase_site_clicked'
            ? { link_text: singleMenuItem.label.toLowerCase() }
            : { button_text: singleMenuItem.label, button_id: singleMenuItem.elementId }),
        });
      }
    };

    return (
      <li key={`${renderSingleMenuElement}-${singleMenuItem.id}`} className={classes.listElement}>
        <MenuItemElement
          url={prepareURL(linkURL)}
          isLabel={singleMenuItem.groupLabel}
          numberId={index}
          isNativeLink={
            singleMenuItem?.page?.useNativeLinks ||
            prepareURL(linkURL).includes('xpath-selector-builder')
          }
          elementClassname={singleMenuItem.elementClassname}
          elementId={singleMenuItem.elementId}
          onClick={handleClick}
          onAuxClick={handleClick}
          openInNewTab={singleMenuItem.openInNewTab}
        >
          {singleMenuItem.label}
        </MenuItemElement>
      </li>
    );
  };

  const renderMenuColumn = (menuColumn) => (
    <div className={classes.menuSlot} key={`renderMenuColumn-${menuColumn.id}`}>
      <ul className={classes.listContainer}>
        {menuColumn?.menuItems.map(renderSingleMenuElement) || null}
      </ul>
    </div>
  );

  const renderMenu = R.cond([
    [R.propEq('__component', COMPONENT_TYPE.FOOTER_MENU_COLUMN), renderMenuColumn],
    [R.T, R.always(null)],
  ]);

  return menu?.list.map(renderMenu) || null;
};

FooterMenu.propTypes = {
  menu: dataTypes.footerMenuData.props,
};

FooterMenu.defaultProps = {
  menu: dataTypes.footerMenuData.default,
};

export default FooterMenu;
