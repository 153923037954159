import classNames from 'classnames';

import type { TestimonialSection, TestimonialsList } from '~/types/models';

import Image from '~/components/Image/Image';
import { getUploadAssetsURL } from '~/utils/functions';

import Testimonial from '../../Testimonial/Testimonial';

import classes from './Testimonials.module.scss';

const Testimonials = ({ testimonials }: TestimonialsList) => {
  const renderDeprecatedTestimonial = ({ testimonial }: TestimonialSection, index: number) => {
    // TODO: Migrate to dense variant of SimpleTestimonial

    if (!testimonial) {
      // eslint-disable-next-line no-console
      console.error('Testimonial data is missing for item with index', index);
      return null;
    }

    const { author } = testimonial;

    return (
      <div className={classes.item} key={testimonial.id}>
        {testimonial.logo && (
          <div className={classes.item__logo}>
            <Image
              loading="lazy"
              src={getUploadAssetsURL(testimonial.logo?.url)}
              alt={testimonial.logo?.alternativeText}
              width={110}
              height={64}
              className={classes.item__iconImage}
              objectFit="contain"
              objectPosition="left"
            />
          </div>
        )}
        <div className={classes.item__content}>
          <div className={classes.item__icon}>
            <Image
              src="/assets/quote-mark.png"
              loading="lazy"
              alt="quote mark"
              width={64}
              height={47}
              className={classes.item__iconImage}
            />
          </div>
          <div className={classes.item__texts}>
            <div className={classes.item__main}>&quot;{testimonial.text}&quot;</div>
            {author && (
              <div className={classes.item__author}>
                — {author.name}, {author.role}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTestimonial = (testimonialSection: TestimonialSection, index: number) =>
    testimonialSection.variant === 'simple' ? (
      renderDeprecatedTestimonial(testimonialSection, index)
    ) : (
      <Testimonial content={testimonialSection} dense />
    );

  const containerClassName = classNames(classes.container, {
    [classes.twoColumns]: testimonials.length === 2,
    [classes.threeColumns]: testimonials.length >= 3,
  });
  return <div className={containerClassName}>{testimonials.map(renderTestimonial)}</div>;
};

export default Testimonials;
