import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';

import PopupComponent from '../Popup';
import { isRouteMatchPattern } from '../Popup/Popup.helpers';

function Popups() {
  const router = useRouter();
  const sharedData = useContext(SharedDataContext);

  const matchesAnyUrlFromList = (url: string, list = '') => {
    if (!list) {
      return false;
    }
    return list?.split('\n').some((pattern) => isRouteMatchPattern(pattern, url));
  };

  const popupsOnThisPage = useMemo(
    () =>
      sharedData.popups?.filter((popup) => {
        const path = router.asPath.replace(/\?.+/, '');
        if (matchesAnyUrlFromList(path, popup.excludedUrl)) {
          return false;
        }
        return matchesAnyUrlFromList(path, popup.includedUrl);
      }),
    [sharedData.popups, router.asPath],
  );

  return popupsOnThisPage?.map((popup) => (
    <PopupComponent key={popup.id} content={{ title: '', popup }} />
  ));
}

export default Popups;
