import { getUploadAssetsURL } from '../../../../utils/functions';
import { dataTypes } from '../../../../utils/propTypes';
import Image from '../../../Image/Image';

import classes from './AboutUsPictures.module.scss';

const AboutUsPictures = ({ content }) => {
  const renderSingleTeamMember = (section) => (
    <div
      className={classes.AboutUsPictures__singlePerson}
      key={`renderSingleTeamMember-${section.id}`}
    >
      {section.picture ? (
        <Image
          src={getUploadAssetsURL(section.picture.url)}
          height="220"
          width="220"
          alt={`${section.name} - ${section.role}`}
        />
      ) : null}
      {section.name ? (
        <div className={classes.AboutUsPictures__singlePersonOverlay}>
          <span className={classes.AboutUsPictures__singlePersonOverlayName}>{section.name}</span>
          <span className={classes.AboutUsPictures__singlePersonOverlayRole}>{section.role}</span>
        </div>
      ) : null}
    </div>
  );

  return (
    <section className={classes.AboutUsPictures}>
      <div className={classes.AboutUsPictures__inner}>
        <div className={classes.AboutUsPictures__personCollection}>
          {content?.singlePerson.map(renderSingleTeamMember)}
        </div>
      </div>
    </section>
  );
};

AboutUsPictures.propTypes = {
  content: dataTypes.aboutUsPictures.props,
};

AboutUsPictures.defaultProps = {
  content: dataTypes.aboutUsPictures.deafult,
};

export default AboutUsPictures;
