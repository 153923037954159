import Content from '../Content/Content';

interface WebflowContentProps {
  className?: string;
  content: string;
  type: string;
  inline?: boolean;
}

// eslint-disable-next-line react/prop-types
const WebflowContent = (props: WebflowContentProps): JSX.Element => (
  // eslint-disable-next-line react/no-danger
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Content {...props} inline />
);

export default WebflowContent;
