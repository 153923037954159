/* eslint-disable import/no-unresolved */
/* eslint-disable react/display-name */
import * as Sentry from '@sentry/nextjs';
import Plyr from 'plyr-react';
import { forwardRef, useRef, useImperativeHandle, useEffect, useState, useCallback } from 'react';

import type { APITypes, PlyrInstance } from 'plyr-react';
import type { GenericVideo } from '~/types/models';
import 'plyr-react/dist/plyr.css';

const videoOptions = {
  fullscreen: {
    enabled: true,
    iosNative: true,
  },
  controls: [
    'play-large',
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'settings',
    'fullscreen',
  ],
};

interface VideoProps {
  data: GenericVideo;
  dynamicVideoRef?: React.Ref<VideoRef>;
}

interface VideoRef {
  play: () => Promise<void>;
  pause: () => Promise<void>;
  playOnFullscreen: () => void;
}

const Video = forwardRef<VideoRef, VideoProps>((props, ref) => {
  const [isLoaded, setLoaded] = useState(false);

  const plyrRef = useRef<APITypes & { plyr: PlyrInstance }>(null);

  const {
    data: { url, hash, ext },
  } = props;

  const isProduction = process.env.NODE_ENV === 'production';
  const productionVideoPath = url.replace(hash, `hls/${hash}`).replace(ext, '.m3u8');

  // Inject hls.js
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = '/scripts/hls.js';
    scriptTag.addEventListener('load', () => setLoaded(true));
    scriptTag.addEventListener('error', () => {
      Sentry.captureException(new Error('Error while loading hls.js'));
    });
    document.body.appendChild(scriptTag);
  }, []);

  // Loading the video
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoaded && plyrRef.current) {
      // @ts-expect-error media is not defined in plyr object but exists
      const player = plyrRef.current.plyr?.media;

      // @ts-expect-error Hls is not defined
      if (isProduction && window.Hls.isSupported()) {
        // @ts-expect-error Hls is not defined
        const { Hls } = window;
        const hls = new Hls();

        hls.loadSource(productionVideoPath);
        hls.attachMedia(player);

        player.addEventListener(
          'play',
          () => {
            hls.startLoad();
          },
          { once: false },
        );

        player.addEventListener(
          'pause',
          () => {
            hls.stopLoad();
          },
          { once: false },
        );

        return () => hls.detachMedia();
      }
      player.src = url;
      player.type = 'video/mp4';
    }
  }, [isLoaded, ref, isProduction, productionVideoPath, url]);

  const play = useCallback(async () => {
    try {
      await plyrRef.current?.plyr?.play();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  useImperativeHandle(
    // eslint-disable-next-line react/prop-types
    props.dynamicVideoRef ?? ref,
    () => ({
      play,
      pause: async () => {
        await play();
        plyrRef.current?.plyr?.pause();
      },
      playOnFullscreen: () => {
        plyrRef.current?.plyr?.play();
        plyrRef.current?.plyr?.fullscreen.enter();
      },
    }),
    [play],
  );

  return <Plyr ref={plyrRef} options={videoOptions} />;
});

export default Video;
