/* eslint-disable @next/next/no-img-element, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
import classNames from 'classnames';
import { useState } from 'react';

import type { BaseSectionProps } from '../BaseSection/BaseSection';

import chevronIcon from '~/public/assets/icons/chevron-down-1.svg';

import BaseSection from '../BaseSection/BaseSection';

import classes from './ExpandableSection.module.scss';

interface ExpandableSectionProps extends BaseSectionProps {
  title: string;
  defaultExpanded?: boolean;
}

const ExpandableSection = ({
  defaultExpanded = true,
  children,
  title,
  ...baseSectionProps
}: ExpandableSectionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleHeaderClick = () => {
    setExpanded(!expanded);
  };

  const headerClassName = classNames(classes.header, {
    [classes.expanded]: expanded,
  });

  const containerClassName = classNames(classes.container, baseSectionProps.className);

  return (
    <BaseSection {...baseSectionProps} className={containerClassName}>
      <div role="button" onClick={handleHeaderClick} className={headerClassName}>
        <h3>{title}</h3>
        <img
          src={chevronIcon.src}
          alt={`${title} section arrow`}
          className={expanded ? classes.arrowUp : classes.arrowDown}
        />
      </div>
      {expanded && <p>{children}</p>}
    </BaseSection>
  );
};

export default ExpandableSection;
