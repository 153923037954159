import Link from 'next/link';

import { getCareersUrl } from '~/utils/urls';

import { dataTypes } from '../../../../utils/propTypes';
import RoundedButton from '../../../Buttons/RoundedButton/RoundedButton';
import {
  BUTTON_BG_COLORS,
  BUTTON_SIZES,
} from '../../../Buttons/RoundedButton/RoundedButton.constants';

import classes from './JobOffersList.module.scss';

const JobOffersList = ({ content }) => {
  const { offers } = content;

  return (
    <section className={classes.JobOffersList__container}>
      {offers.length > 0 ? (
        <ul className={classes.JobOffersList__list}>
          {offers.map((offer) => (
            <li key={offer.id} className={classes.JobOffersList__item}>
              <div className={classes.JobOffersList__contentWrapper}>
                <div className={classes.JobOffersList__contentColumn}>
                  <Link href={getCareersUrl(offer)} legacyBehavior>
                    <a className={classes.JobOffersList__title}>{offer.title}</a>
                  </Link>
                  <span className={classes.JobOffersList__location}>{offer.location}</span>
                </div>
                <div className={classes.JobOffersList__contentColumn}>
                  <span className={classes.JobOffersList__salary}>
                    {offer.salaryMin} - {offer.salaryMax} {offer.currency}
                  </span>
                </div>
              </div>
              <div>
                <RoundedButton
                  className={classes.JobOffersList__detailsLink}
                  content={{
                    slug: getCareersUrl(offer),
                    label: 'details',
                    backgroundColor: BUTTON_BG_COLORS.YELLOW,
                    size: BUTTON_SIZES.SMALL,
                    withBorder: false,
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>There is no job offers</p>
      )}
    </section>
  );
};

JobOffersList.propTypes = {
  content: dataTypes.jobOffersList.props,
};

JobOffersList.defaultProps = {
  content: dataTypes.jobOffersList.default,
};

export default JobOffersList;
