import { useRouter } from 'next/router';
import urlcat from 'urlcat';

import DirectionArrow from './DirectionArrow/DirectionArrow';
import { ARROW_DIRECTIONS } from './DirectionArrow/DirectionArrow.constants';
import classes from './Pagination.module.scss';
import PaginationButton from './PaginationButton/PaginationButton';

interface PaginationProps {
  pagination: {
    total: number;
    postsPerPage: number;
    page: number;
  };
}

const Pagination = ({ pagination }: PaginationProps) => {
  const router = useRouter();
  const paginationBaseURL = router.asPath.split('/page')[0];
  const { total = 0, postsPerPage = 0, page = 1 } = pagination;
  const totalPaginationPages = Math.ceil(total / postsPerPage);

  const prevStep = Math.min(Math.max(page - 1, 1), totalPaginationPages);
  const nextStep = Math.min(page + 1, totalPaginationPages);
  const doubleNextStep = Math.min(page + 2, totalPaginationPages);

  // URLS like '/page/0/' amd '/page/1/' are redundant, we trim that to avoid generate redirected URLs in HTML.
  const prevStepURL = [0, 1].includes(prevStep)
    ? paginationBaseURL
    : urlcat(paginationBaseURL, '/page/:page/', { page: prevStep });

  const nextStepURL = urlcat(paginationBaseURL, '/page/:page/', { page: nextStep });
  const doubleNextStepURL = urlcat(paginationBaseURL, '/page/:page/', { page: doubleNextStep });
  const lastStepURL = urlcat(paginationBaseURL, '/page/:page/', { page: totalPaginationPages });

  // Do not show pagination, if there is not enough content.
  if (totalPaginationPages <= 1) {
    return null;
  }

  return (
    <nav className={classes.Pagination}>
      <ul className={classes.Pagination__listGroup}>
        <DirectionArrow
          direction={ARROW_DIRECTIONS.LEFT}
          url={prevStepURL}
          show={![0, 1].includes(page)}
        />
        <PaginationButton
          label={prevStep.toString()}
          show={![0, 1].includes(page)}
          url={prevStepURL}
        />
        <PaginationButton label={page.toString()} current show url="" />
        <PaginationButton
          label={nextStep.toString()}
          show={page !== totalPaginationPages}
          url={nextStepURL}
        />
        <PaginationButton
          label={doubleNextStep.toString()}
          show={nextStep !== doubleNextStep && doubleNextStep !== totalPaginationPages}
          url={doubleNextStepURL}
        />
        <PaginationButton
          label="..."
          disabled
          show={![0, 1].includes(page) && nextStep !== totalPaginationPages}
          url=""
        />
        <PaginationButton
          label={totalPaginationPages.toString()}
          show={![0, 1].includes(page) && nextStep !== totalPaginationPages}
          url={lastStepURL}
        />
        <DirectionArrow
          direction={ARROW_DIRECTIONS.RIGHT}
          url={nextStepURL}
          show={page !== totalPaginationPages}
        />
      </ul>
    </nav>
  );
};

export default Pagination;
