import { dataTypes } from '../../../../utils/propTypes';

import classes from './EmptyContent.module.scss';

const EmptyContent = ({ emptyListText }) => (
  <div className={classes.EmptyCategory}>
    <div className={classes.EmptyCategory__inner}>
      <div className={classes.EmptyCategory__div}>
        <h1>{emptyListText}</h1>
      </div>
    </div>
  </div>
);

EmptyContent.propTypes = {
  emptyListText: dataTypes.emptyListText.props,
};

EmptyContent.defaultProps = {
  emptyListText: dataTypes.emptyListText.default,
};

export default EmptyContent;
