import type { ImageProps } from 'next/legacy/image';
import type { BubbleTestimonialSection, TestimonialSection } from '~/types/models';

import BaseSection from '~/components/BaseSection/BaseSection';
import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import Image from '~/components/Image/Image';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';

import classes from './Testimonial.module.scss';
import { TestimonialAuthor } from './TestimonialAuthor';
import { TestimonialMark } from './TestimonialMark';

interface BubbleTestimonialProps
  extends Pick<
    BubbleTestimonialSection,
    'testimonial' | 'withMarginBottom' | 'buttonLabel' | 'buttonUrl'
  > {
  className?: string;
  dense?: boolean;
}

export const BubbleTestimonial = ({
  className,
  testimonial,
  buttonLabel,
  buttonUrl,
  withMarginBottom,
  dense,
}: BubbleTestimonialProps) => {
  const renderQuote = (text: TestimonialSection['testimonial']['text']) => (
    // eslint-disable-next-line react/no-danger
    <div className={classes.quote} dangerouslySetInnerHTML={createMarkup(text)} />
  );

  const imageProps: Partial<ImageProps> = {
    layout: 'fill',
    objectFit: 'contain',
  };

  return (
    <BaseSection
      background="Transparent"
      className={dense ? classes.baseDenseBubbleSection : ''}
      contentClassName={classes.baseBubbleContent}
      withMarginBottom={withMarginBottom}
    >
      <div className={className}>
        <div className={classes.quotationMark}>
          <TestimonialMark width={96} height={71} />
        </div>
        <div className={classes.content}>
          {/*  For dense version: top logo */}
          {dense && <TestimonialAuthor logo={testimonial.logo} imageProps={imageProps} />}
          {!dense && testimonial.author?.image && (
            <div className={classes.authorImageContainer}>
              <Image
                className={classes.logo}
                src={getUploadAssetsURL(testimonial.author.image.url)}
                alt={testimonial.author.image.alternativeText}
                loading="lazy"
                layout="fill"
                objectFit="cover"
                objectPosition="left"
              />
            </div>
          )}
          <div className={classes.texts}>
            {renderQuote(testimonial.text)}

            {testimonial.author?.name && (
              <TestimonialAuthor
                author={testimonial.author}
                logo={testimonial.logo}
                imageProps={imageProps}
              />
            )}

            {buttonUrl && buttonLabel && (
              <div className={classes.buttonContainer}>
                <RoundedButton
                  content={{
                    label: buttonLabel,
                    slug: buttonUrl,
                    backgroundColor: 'DarkPurple',
                    size: 'Regular',
                    withBorder: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseSection>
  );
};
