import { getUploadAssetsURL } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import Image from '../../../Image/Image';

import classes from './PerksTable.module.scss';

const PerkIcon = ({ icon }) => (
  <div className={classes.PerksTable__PerkIcon}>
    {icon ? (
      <Image
        src={getUploadAssetsURL(icon.url)}
        alt={icon.alternativeText}
        height="29"
        width="29"
        unoptimized
      />
    ) : null}
  </div>
);

const PerksTable = ({ data }) => {
  const renderSinglePerk = (section) => (
    <div className={classes.PerksTable__singlePerk} key={`renderSinglePerk-${section.id}`}>
      <div className={classes.PerksTable__singlePerkSymbol}>
        <PerkIcon icon={section.icon} />
      </div>
      <h4 className={classes.PerksTable__singlePerkTitle}>{section.title}</h4>
      <div className={classes.PerksTable__singlePerkDescription}>{section.description}</div>
    </div>
  );

  return (
    <section className={classes.PerksTable}>
      <div className={classes.PerksTable__inner}>{data.map(renderSinglePerk)}</div>
    </section>
  );
};

PerksTable.propTypes = {
  data: dataTypes.perksTable.props,
};

PerksTable.defaultProps = {
  data: dataTypes.perksTable.default,
};

export default PerksTable;
