import type {
  GenericButton,
  GenericComponent,
  GenericImage,
  GenericMedia,
  GenericVideo,
  SectionBackground,
} from './base';
import type {
  Article,
  ArticleCategory,
  Author,
  CaseStudy,
  Client,
  Integration,
  Popup,
  Solution,
  Testimonial,
} from './collections';
import type { URLString } from '../aliases';

export interface RedirectRecord extends GenericComponent {
  source: URLString;
  destination: URLString;
  permanent: boolean;
}

export interface RewriteRecord extends GenericComponent {
  source: URLString;
  destination: URLString;
}

export interface CspSource extends GenericComponent {
  type: 'scripts' | 'styles' | 'connect' | 'media' | 'fonts' | 'images' | 'frames';
  url: URLString;
}

export interface SitemapIgnoredPath extends GenericComponent {
  relativePathPattern: URLString;
}

export interface SinglePopup extends GenericComponent {
  title: string;
  popup: Popup;
}

export interface SidebarTitle extends GenericComponent {
  title: string;
}

export interface SolutionPrimitive extends GenericComponent {
  solution: Solution;
  title: string;
  description: string;
  perks: string;
  image: GenericImage;
  showMoreButton: boolean;
}
export interface MenuItem extends GenericComponent {
  label: string;
  url: string;
  elementClassname: string;
  page: { slug: string };
}

export interface ToolsComparisonsSection extends GenericComponent {
  title: string;
  comparisons: ToolsComparison[];
}

export interface ToolsComparison extends GenericComponent {
  title: string;
  referenceToolName: string;
  referenceToolDescription: string;
  secondToolName: string;
  secondToolDescription: string;
}

// Components
export type PageContent = Array<GenericComponent | Menu | FullWidthSection>;

export interface Seo extends GenericComponent {
  title: string;
  description: string;
  image: GenericImage;
  canonical: string;
  noIndex: boolean;
  microformat: string;
}

export interface Menu extends GenericComponent {
  menuItems: MenuItem[];
  elementClassnameSubscribe: string;
}

export interface SectionTitle extends GenericComponent {
  title: string;
  backgroundColor?: SectionBackground;
}

export interface PopupTitle extends GenericComponent {
  title: string;
}

export interface ExtraSpace extends GenericComponent {
  desktop: number;
  tablet: number;
  mobile: number;
  background: SectionBackground;
}

export interface TextWithImageSection extends GenericComponent {
  sectionTitle: string;
  sectionContent: string;
  withMarginBottom: boolean;
  sectionDirection: 'isRightImage' | 'isLeftImage';
  sectionBackground: SectionBackground;
  sectionCTAButton?: GenericButton;
  kicker?: string;
  picture?: GenericImage;
  showPictureShadow?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  fontSize?: 'regular' | 'large';
}

export interface TableCompareSection extends GenericComponent {
  title: string;
  withMarginBottom: boolean;
  firstColumn: TableCell[];
  secondColumn: TableCell[];
  thirdColumn: TableCell[];
}

export type TableCell = TableFirstCell | TableRegularCell;

export interface TableFirstCell extends TableCellCommon {
  variant: 'unknown' | 'text';
  tooltip: string;
}

export interface TableRegularCell extends TableCellCommon {
  tooltip: never;
}

interface TableCellCommon extends GenericComponent {
  variant: 'checked' | 'unchecked' | 'unknown' | 'text';
  content?: string;
  tooltip?: string;
}

export interface FullWidthSection extends GenericComponent {
  sectionContent: string;
  sectionDescription: string;
  sectionBackground: SectionBackground;
  sectionCTA: GenericButton[];
  withMarginBottom: boolean;
}

export interface SolutionsSection extends GenericComponent {
  title?: string;
  solutions: SolutionPrimitive[];
}

export interface RelatedPosts extends GenericComponent {
  title?: string;
  articles: Article[];
}

export interface IntegrationsList extends GenericComponent {
  title?: string;
  integrations: Integration[];
}

export interface CaseStudiesList extends GenericComponent {
  caseStudies: CaseStudy[];
}

export interface TestimonialsList extends GenericComponent {
  testimonials: TestimonialSection[];
}

export interface ClientsLogosList extends GenericComponent {
  clients: Client[];
  title?: string;
  withBottomMargin?: boolean;
}

export type TestimonialSection = BubbleTestimonialSection | CommonTestimonialSection;

export interface BubbleTestimonialSection extends CommonTestimonialSection {
  variant: 'bubble';
  buttonLabel?: string;
  buttonUrl?: string;
}

export interface CommonTestimonialSection extends GenericComponent {
  testimonial: Testimonial;
  sectionBackground: SectionBackground;
  variant: TestimonialSectionVariant;
  withMarginBottom: boolean;
}

export interface Banner extends GenericComponent {
  title: string;
  image: GenericImage;
  url: URLString;
  position: 'left' | 'center' | 'right';
  withMarginBottom: boolean;
  maxWidthPx: number;
}

export interface CategoriesList extends GenericComponent {
  title?: string;
  categories: ArticleCategory[];
}

export interface AuthorsList extends GenericComponent {
  title?: string;
  authors: Author[];
}

export interface RecentArticlesList extends GenericComponent {
  title?: string;
}

export interface ArticlesList extends GenericComponent {
  articles: Article[];
  articleCategoryHidden: boolean;
  emptyListText: string;
  width?: 'full' | 'column';
  background?: SectionBackground;
  title?: string;
}

export interface ContentTitle extends GenericComponent {
  kicker?: string;
  title?: string;
  subtitle?: string;
  withBottomMargin: boolean;
  variant: 'default' | 'condensed';
}

export interface ArticlesSection extends GenericComponent {
  articles: ArticlesList;
  background?: SectionBackground;
  title?: string;
}

export interface HeroCTASection extends GenericComponent {
  topImage?: GenericImage;
  background?: SectionBackground;
  title: string;
  subtitle?: string;
  description?: string;
  button: GenericButton;
}

export interface ActiveCampaignForm extends GenericComponent {
  formId: number;
  button: GenericButton;
  fileToDownloadOnSubmit?: GenericMedia;
  closeActiveModalsOnSubmit?: boolean;
}

export interface StickyBarHeader extends GenericComponent {
  title: string;
  icon?: GenericImage;
}

export interface G2Widget extends GenericComponent {
  variant: 'row' | 'column';
}

type TestimonialSectionVariant = 'simple' | 'section' | 'sectionCenter' | 'bubble';

export const isBubbleTestimonialSection = (
  section: TestimonialSection,
): section is BubbleTestimonialSection => section.variant === 'bubble';

// Type guards
export const isVideoData = (data: GenericMedia): data is GenericVideo =>
  data && data.mime.startsWith('video');
