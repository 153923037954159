import type { StickyBarHeader as StickyBarHeaderContent } from '~/types/models';

import Media from '~/components/Media/Media';

import classes from './StickyBarHeader.module.scss';

interface StickyBarHeaderProps {
  content: StickyBarHeaderContent;
}

export const StickyBarHeader = ({ content: { icon, title } }: StickyBarHeaderProps) => (
  <div className={classes.container}>
    {icon && <Media className={classes.icon} data={icon} />}
    <p className={classes.title}>{title}</p>
  </div>
);
