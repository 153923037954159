import classNames from 'classnames';

import { ExtraSpace } from '~/types/models';

import classes from './ExtraSpace.module.scss';

export interface ExtraSpaceProps {
  content: ExtraSpace;
}

const ExtraSpace = ({ content }: ExtraSpaceProps) => {
  const style = {
    '--desktop-height': `${content.desktop ?? 0}px`,
    '--tablet-height': `${content.tablet ?? content.desktop ?? 0}px`,
    '--mobile-height': `${content.mobile ?? content.tablet ?? content.desktop ?? 0}px`,
  };

  const mergedClasses = classNames(classes.extraSpace, classes[content.background]);

  // @ts-expect-error Variables are set in style, which is not recognized by TS
  return <div className={mergedClasses} style={style} />;
};

export default ExtraSpace;
