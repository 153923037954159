import type { ImageProps } from 'next/legacy/image';

import Image from '~/components/Image/Image';

import classes from './Testimonial.module.scss';

export const TestimonialMark = (props: Partial<ImageProps>) => (
  <Image
    width={64}
    height={47}
    {...props}
    src="/assets/quote-mark.png"
    className={classes.quotationMark}
    loading="lazy"
    alt="quote mark"
  />
);
