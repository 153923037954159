import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { isMobile } from '~/utils/functions';

export const useOnIdle = ({
  desktopMs,
  mobileMs,
  callback,
}: {
  desktopMs?: number;
  mobileMs?: number;
  callback: () => void;
}) => {
  const [lastInteraction, setLastInteraction] = useState(Date.now());
  const debounceMs = typeof window !== 'undefined' && isMobile() ? mobileMs : desktopMs;
  const isEnabled = debounceMs !== undefined;

  useDebounce(
    () => {
      if (!isEnabled) return;
      callback();
    },
    debounceMs,
    isEnabled ? [lastInteraction, callback] : [],
  );

  useEffect(() => {
    const onInteraction = () => {
      setLastInteraction(Date.now());
    };

    if (isEnabled) {
      window.addEventListener('mousemove', onInteraction, true);
      window.addEventListener('pointerdown', onInteraction, true);
      window.addEventListener('keydown', onInteraction, true);
      window.addEventListener('click', onInteraction, true);
      window.addEventListener('scroll', onInteraction, true);
    }

    return () => {
      window.removeEventListener('mousemove', onInteraction, true);
      window.removeEventListener('pointerdown', onInteraction, true);
      window.removeEventListener('keydown', onInteraction, true);
      window.removeEventListener('click', onInteraction, true);
      window.removeEventListener('scroll', onInteraction, true);
    };
  }, [isEnabled]);
};
