import type { SharedData } from '~/contexts/sharedData';

import { CategoriesList } from '~/types/models';
import { getCategoryUrl } from '~/utils/urls';

import LinkList from '../LinkList/LinkList';

interface CategoriesListProps {
  content: CategoriesList;
  sharedData: SharedData;
}

const CategoriesList = ({ content, sharedData }: CategoriesListProps) => {
  const { categories: fullCategoriesList = [] } = sharedData;
  const { title } = content;

  const categoriesList = fullCategoriesList.map((category) => ({
    name: category.name,
    url: getCategoryUrl(category),
  }));

  return <LinkList title={title} data={categoriesList} />;
};

export default CategoriesList;
