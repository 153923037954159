import classNames from 'classnames';

import RoundedButton from '../../../Buttons/RoundedButton/RoundedButton';

import classes from './CTASection.module.scss';

const CTASection = ({ data }) => {
  const renderSectionButtons = (section) => (
    <div className={classes.CTASection__buttonHolder} key={`renderSectionButtons-${section.id}`}>
      <RoundedButton content={section} />
    </div>
  );

  const elementClassNames = [
    classNames(classes.CTASection, {
      [classes.withBottomMargin]: data.withMarginBottom,
      [classes.condensed]: data.variant === 'condensed',
    }),
  ].join(' ');

  return (
    <section className={elementClassNames}>
      <div className={classes.CTASection__inner}>{data?.buttons?.map(renderSectionButtons)}</div>
    </section>
  );
};

export default CTASection;
