import classes from './EmptyContent.module.scss';

const EmptyContent = () => (
  <div className={classes.EmptyContent}>
    <div className={classes.EmptyContent__inner}>
      <div className={classes.EmptyContent__div}>This is empty page. Add content in CMS.</div>
    </div>
  </div>
);

export default EmptyContent;
