import classnames from 'classnames';
import Link from 'next/link';

import type { Article } from '~/types/models';

import Image from '~/components/Image/Image';
import { NUMBER_OF_COLUMNS } from '~/utils/constants';
import { getUploadAssetsURL } from '~/utils/functions';
import { getArticleUrl } from '~/utils/urls';

import PlaceholderImage from '../../../PlaceholderImage/PlaceholderImage';
import PostMeta from '../../PostMeta/PostMeta';

import classes from './PostListArticle.module.scss';

interface PostListArticleProps {
  data: Article;
  columns: 'two' | 'three';
  categoryHidden?: boolean;
  className?: string;
  ArticleHeading?: React.ElementType;
}

const PostListArticle = ({
  data: article,
  columns = 'two',
  categoryHidden = true,
  className = '',
  ArticleHeading = 'h2',
}: PostListArticleProps) => {
  const elementClassNames = [
    classnames(classes.PostListArticle, className, {
      [classes[NUMBER_OF_COLUMNS.TWO]]: columns === 'two',
      [classes[NUMBER_OF_COLUMNS.THREE]]: columns === 'three',
    }),
  ].join(' ');

  return (
    <article className={elementClassNames}>
      <div className={classes.PostListArticle__inner}>
        <Link href={getArticleUrl(article)} legacyBehavior>
          <a className={classes.PostListArticle__linkWrapp} tabIndex={-1} aria-hidden="true">
            {article.title}
          </a>
        </Link>
        <div className={classes.PostListArticle__imageContainer}>
          <div className={classes.PostListArticle__imageWrapper}>
            {article.featuredImage && article.featuredImage.url ? (
              <Image
                className={classes.PostListArticle__image}
                src={getUploadAssetsURL(article.featuredImage.url)}
                alt={article.featuredImage.alternativeText}
                width="384"
                height="240"
                layout="intrinsic"
              />
            ) : (
              <PlaceholderImage
                width="384"
                height="240"
                layout="intrinsic"
                cn={classes.PostListArticle__image}
              />
            )}
          </div>
        </div>
        {!categoryHidden && (
          <div className={classes.PostListArticle__categoryName}>
            {article.article_category.name}
          </div>
        )}
        <ArticleHeading className={classes.PostListArticle__title}>{article.title}</ArticleHeading>
        <p className={classes.PostListArticle__excerpt}>{article.excerpt}</p>
        <PostMeta authors={article.authors} date={article.publishDate} />
        <span className={classes.PostListArticle__readMore}>
          READ MORE <span className={classes.PostListArticle__readMoreIcon} />
        </span>
      </div>
    </article>
  );
};

export default PostListArticle;
