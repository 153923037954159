import type { TestimonialProps } from './Testimonial.types';
import type { TestimonialSection } from '~/types/models';

import BaseSection from '~/components/BaseSection/BaseSection';
import Image from '~/components/Image/Image';
import { isBubbleTestimonialSection } from '~/types/models';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';

import { BubbleTestimonial } from './BubbleTestimonial';
import { SimpleTestimonial } from './SimpleTestimonial';
import classes from './Testimonial.module.scss';
import { TestimonialAuthor } from './TestimonialAuthor';

const Testimonial = ({ content, dense = false }: TestimonialProps) => {
  if (!content) return null;

  const { testimonial, variant, sectionBackground, withMarginBottom } = content;
  const containerClassNames = [
    classes.container,
    classes[variant],
    classes[`${sectionBackground}Background`],
    withMarginBottom ? classes.withMarginBottom : '',
    dense ? classes.dense : '',
  ].join(' ');

  const renderQuote = (text: TestimonialSection['testimonial']['text']) => (
    // eslint-disable-next-line react/no-danger
    <div className={classes.quote} dangerouslySetInnerHTML={createMarkup(text, true)} />
  );

  if (isBubbleTestimonialSection(content)) {
    return (
      <BubbleTestimonial
        className={containerClassNames}
        testimonial={testimonial}
        buttonLabel={content.buttonLabel}
        buttonUrl={content.buttonUrl}
        withMarginBottom={withMarginBottom}
        dense={dense}
      />
    );
  }

  if (['section', 'sectionCenter'].includes(variant)) {
    const userImage = testimonial.author?.image && (
      <div className={classes.authorImageContainer}>
        <Image
          className={classes.logo}
          src={getUploadAssetsURL(testimonial.author.image.url)}
          alt={testimonial.author.image.alternativeText}
          loading="lazy"
          layout="fill"
          objectFit="cover"
          objectPosition="left"
        />
      </div>
    );
    return (
      <BaseSection
        background={sectionBackground}
        contentClassName={classes.baseSectionContent}
        withMarginBottom={withMarginBottom}
      >
        <div className={containerClassNames}>
          <div className={classes.content}>
            <div className={classes.texts}>
              {variant === 'sectionCenter' && userImage}
              {renderQuote(`&ldquo;${testimonial.text}&rdquo;`)}
              <TestimonialAuthor author={testimonial.author} logo={testimonial.logo} />
            </div>
            {variant === 'section' && userImage}
          </div>
        </div>
      </BaseSection>
    );
  }

  return <SimpleTestimonial className={containerClassNames} testimonial={testimonial} />;
};

export default Testimonial;
