import { SidebarTitle } from '~/types/models';

import classes from './SidebarTitle.module.scss';

interface SidebarTitleProps {
  content: SidebarTitle;
}

const SidebarTitle = ({ content }: SidebarTitleProps) => (
  <div className={classes.title}>
    <strong>{content.title}</strong>
  </div>
);

export default SidebarTitle;
