import type { HTMLMotionProps, Variants } from 'framer-motion';
import type { SinglePopup } from '~/types/models';

const transition = { duration: 0.5, ease: [0.16, 1, 0.3, 1] };

const getSlideIn = (position: SinglePopup['popup']['position']): Variants => {
  switch (position) {
    case 'bottomLeft':
    case 'topLeft':
      return {
        initial: { x: `-100%` },
        animate: { x: 0 },
      };
    case 'bottomRight':
    case 'topRight':
      return {
        initial: { x: `100%` },
        animate: { x: 0 },
      };
    case 'bottomCenter':
      return {
        initial: { y: `100%` },
        animate: { y: 0 },
      };
    case 'topCenter':
      return {
        initial: { y: `-100%` },
        animate: { y: 0 },
      };
    default:
      return {
        initial: { y: `50%`, opacity: 0 },
        animate: { y: 0, opacity: 1 },
      };
  }
};

export const backdropAnimation: HTMLMotionProps<'div'> = {
  initial: { background: '#0000000' },
  animate: { background: '#00000068' },
  exit: { background: '#0000000' },
};

export default { getSlideIn, transition };
