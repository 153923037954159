import BlogPagination from '../../../Pagination/Pagination';
import PostListArticle from '../Appetizers/PostListArticle/PostListArticle';
import EmptyContent from '../EmptyContent/EmptyContent';

import classes from './ListContainer.module.scss';

/*
  @deprecated
*/
const ListContainer = ({ blogPosts, pagination, columns, emptyListText }) => {
  const renderBlogAppetizer = (blogPost) => {
    const { id } = blogPost;
    return <PostListArticle data={blogPost} key={id} columns={columns} />;
  };

  return (
    <>
      <div className={classes.ListContainer}>
        {blogPosts.length ? (
          blogPosts.map(renderBlogAppetizer)
        ) : (
          <EmptyContent emptyListText={emptyListText} />
        )}
      </div>
      <BlogPagination pagination={pagination} />
    </>
  );
};

export default ListContainer;
