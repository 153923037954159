import { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import type { ReactNode } from 'react';

const StickyBarContext = createContext<{
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
} | null>(null);

export const StickyBarProvider = ({ children, id }: { children: ReactNode; id: string }) => {
  const [isVisible, setIsVisible] = useLocalStorage(`show-sticky-bar-${id}`, true);

  const value = useMemo(
    () => ({
      isVisible: isVisible ?? false,
      setIsVisible,
    }),
    [isVisible, setIsVisible],
  );

  return <StickyBarContext.Provider value={value}>{children}</StickyBarContext.Provider>;
};

const defaults = {
  isVisible: false,
  setIsVisible: () => {
    console.error('Could not find parent StickyBarProvider');
    // noop
  },
};
export const useParentStickyBar = () => {
  const context = useContext(StickyBarContext);

  return context ?? defaults;
};
