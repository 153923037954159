import classnames from 'classnames';
import Image from 'next/image';
import { useState } from 'react';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import Help from '~/components/Help';
import checkIcon from '~/public/assets/icons/check.svg';
import { createMarkup } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import classes from './PricingPlans.module.scss';

const PRICING_TYPES = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
};
const CURRENCY_SYMBOLS = {
  EURO: 'EUR',
  DOLAR: '$',
  CUSTOM: '',
};
const CURRENCY_TYPE = {
  EURO: 'euro',
  DOLAR: 'dolar',
  CUSTOM: 'custom',
};

const PricingPlans = ({ content }) => {
  const [pricingType, setPricingType] = useState(PRICING_TYPES.ANNUAL);
  const hasAnyDescription = content?.plans.flatMap((p) => p.pricing).some((p) => p.description);

  const renderExtraPerks = (section, index) => (
    <li
      key={`renderFeaturesListElements-${section.id}`}
      className={classes.PricingPlans__singlePlanFeatureListElement}
      aria-describedby={`renderFeaturesListElements-${section.id}`}
    >
      <Help content={section.tooltip}>
        <span className={classes.perk}>
          {index > 0 && (
            <Image
              src={checkIcon}
              width={16}
              height={16}
              alt="check"
              className={classes.perkIcon}
            />
          )}
          <span
            className={classes.PricingPlans__singlePlanFeatureListElementLabel}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(section.element || '', true)}
          />
        </span>
      </Help>
    </li>
  );
  const renderSinglePricingElement = (singlePricingPlan) => {
    const currencyType = singlePricingPlan.currency.toUpperCase();
    return (
      <div
        key={`renderSinglePricingElement-${singlePricingPlan.id}`}
        hidden={pricingType !== singlePricingPlan.priceType}
        aria-hidden={pricingType !== singlePricingPlan.priceType}
      >
        <div className={classes.price}>
          <span
            className={classes.PricingPlans__singlePlanCurrencySymbol}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: CURRENCY_SYMBOLS[currencyType] }}
          />
          {CURRENCY_TYPE.CUSTOM === singlePricingPlan.currency ? (
            <span className={classes.PricingPlans__singlePlanPriceCustom}>Contact us</span>
          ) : (
            <span className={classes.PricingPlans__singlePlanPrice}>
              {singlePricingPlan.price.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          )}
          {singlePricingPlan.annotaton && (
            <span className={classes.PricingPlans__singlePlanPriceAnnotation}>
              {' '}
              {singlePricingPlan.annotaton}
            </span>
          )}
        </div>
        <p
          className={classnames(
            classes.priceDescription,
            CURRENCY_TYPE.CUSTOM === singlePricingPlan.currency && classes.hidden,
          )}
        >
          {singlePricingPlan.description ?? <>&nbsp;</>}
        </p>
      </div>
    );
  };

  const renderSinglePlan = (singlePlan) => (
    <div className={classes.PricingPlans__singlePlan} key={`renderSinglePlan-${singlePlan.id}`}>
      <div className={classes.PricingPlans__singlePlanInner}>
        {singlePlan.popular ? (
          <div className={classes.PricingPlans__singlePlanRibbon}>Popular</div>
        ) : null}
        <div className={classes.PricingPlans__singlePlanTitle}>{singlePlan.title}</div>
        <div className={classes.PricingPlans__singlePlanFeaturesTitle}>
          {singlePlan.smallDescription}
        </div>
        <div
          className={classnames(
            classes.PricingPlans__singlePlanPricing,
            !hasAnyDescription && classes.compactPricing,
          )}
        >
          {singlePlan?.pricing.map(renderSinglePricingElement)}
        </div>
        <div className={classes.PricingPlans__singlePlanCtaContainer}>
          {singlePlan.button ? (
            <RoundedButton
              className={classes.PricingPlans__singlePlanCtaButton}
              content={singlePlan.button}
            />
          ) : null}
        </div>
        <ul className={classes.PricingPlans__singlePlanFeaturesList}>
          {singlePlan?.extraPerks.map(renderExtraPerks)}
        </ul>
      </div>
    </div>
  );

  return (
    <section className={classes.PricingPlans}>
      <div className={classes.PricingPlans__inner}>
        <div className={classes.PricingPlans__pricingSwitcher}>
          <ul className={classes.PricingPlans__pricingSwitcherContainer}>
            <li
              className={classnames(classes.PricingPlans__pricingType, {
                [classes.isPriceTypeSelected]: pricingType === PRICING_TYPES.MONTHLY,
              })}
              onClick={() => setPricingType(PRICING_TYPES.MONTHLY)}
              aria-hidden="true"
            >
              Monthly
            </li>
            <li
              className={classnames(classes.PricingPlans__pricingType, {
                [classes.isPriceTypeSelected]: pricingType === PRICING_TYPES.ANNUAL,
              })}
              onClick={() => setPricingType(PRICING_TYPES.ANNUAL)}
              aria-hidden="true"
            >
              Annually
            </li>
          </ul>
          {content.promoMessage && (
            <div className={classes.PricingPlans__promoMessage}>{content.promoMessage}</div>
          )}
        </div>
        <div className={classes.PricingPlans__row}>{content?.plans.map(renderSinglePlan)}</div>
      </div>
    </section>
  );
};

PricingPlans.propTypes = {
  content: dataTypes.pricingPlans.props,
};

PricingPlans.defaultProps = {
  content: dataTypes.pricingPlans.default,
};

export default PricingPlans;
