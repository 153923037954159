import classnames from 'classnames';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useExitIntent } from 'use-exit-intent';

import type { MouseEvent, ReactNode } from 'react';
import type { GenericButton } from '~/types/models';

import Image from '~/components/Image/Image';
import Link from '~/components/Link/Link';
import { usePopups } from '~/components/Popups/Popups.context';
import { useParentStickyBar } from '~/components/StickyBar/StickyBar.context';
import { trackEvent, trackLinkClick } from '~/lib/mixpanel/mixpanel';
import { getUrlProps, getUploadAssetsURL, isUnknownMouseClick } from '~/utils/functions';

import { BUTTON_BG_COLORS, BUTTON_SIZES } from './RoundedButton.constants';
import classes from './RoundedButton.module.scss';

interface RegularButtonProps {
  type?: HTMLButtonElement['type'];
  className?: string;
  content: Omit<GenericButton, 'slug' | 'id'> & {
    elementClassname?: string;
    isNativeLink?: boolean;
    slug?: undefined;
  };
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}

interface LinkButtonProps {
  type?: HTMLButtonElement['type'];
  className?: string;
  content: Omit<GenericButton, 'id'> & { elementClassname?: string; isNativeLink?: boolean };
}

type RoundedButtonProps = LinkButtonProps | RegularButtonProps;

const isRegularButton = (props: RoundedButtonProps): props is RegularButtonProps =>
  'onClick' in props || !('slug' in props.content) || !!props.content.popupToTrigger;

const RoundedButton = (props: RoundedButtonProps) => {
  const { className, content, type = 'button' } = props;

  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { push } = useRouter();
  const { setHiddenPopup } = usePopups();
  const parentStickyBar = useParentStickyBar();

  const triggerModal = (id: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('popup', id);
    push(`${pathname}?${params.toString()}`, undefined, { shallow: true });
  };

  const elementClassNames = [
    classnames(
      classes.RoundedButton,
      {
        [classes[BUTTON_BG_COLORS.YELLOW]]: content.backgroundColor === BUTTON_BG_COLORS.YELLOW,
        [classes[BUTTON_BG_COLORS.TRANSPARENT]]:
          content.backgroundColor === BUTTON_BG_COLORS.TRANSPARENT,
        [classes[BUTTON_BG_COLORS.DARK_PURPLE]]:
          content.backgroundColor === BUTTON_BG_COLORS.DARK_PURPLE,
        [classes[BUTTON_BG_COLORS.LIGHT_PURPLE]]:
          content.backgroundColor === BUTTON_BG_COLORS.LIGHT_PURPLE,
        [classes[BUTTON_SIZES.REGULAR]]: content.size === BUTTON_SIZES.REGULAR,
        [classes[BUTTON_SIZES.BIG]]: content.size === BUTTON_SIZES.BIG,
        [classes[BUTTON_SIZES.SMALL]]: content.size === BUTTON_SIZES.SMALL,
        [classes.withBorder]: content.withBorder,
      },
      content.elementClassname,
      className,
    ),
  ].join(' ');

  const renderChildren = () => (
    <>
      {content?.icon ? (
        <div className={classes.RoundedButton__icon}>
          <Image
            src={getUploadAssetsURL(content.icon.url)}
            layout="fill"
            alt={content.label}
            unoptimized
          />
        </div>
      ) : null}
      {content.label && <span className={classes.RoundedButton__label}>{content.label}</span>}
    </>
  );

  const id = `bb_popup_${content.popupToDismiss?.id}`;
  const { unsubscribe } = useExitIntent({
    cookie: {
      key: id,
    },
  });

  const hideElements = () => {
    if (content.popupToDismiss) {
      unsubscribe();
      setHiddenPopup(content.popupToDismiss.id);
    }

    if (content.hideParentStickyBar) {
      parentStickyBar.setIsVisible(false);
    }
  };

  if (isRegularButton(props)) {
    const {
      onClick,
      content: { popupToTrigger },
    } = props;

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (isUnknownMouseClick(e)) return;

      onClick?.(e);

      if (popupToTrigger) {
        triggerModal(popupToTrigger.id);
      }

      hideElements();

      if (content.event) {
        trackEvent(content.event.name, {
          placement: content.event.name,
          button_id: content.elementId ?? 'missing-id',
          button_text: content.label,
        });
      }
    };

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        id={content.elementId}
        className={elementClassNames}
        onClick={handleClick}
        onAuxClick={handleClick}
      >
        {renderChildren()}
      </button>
    );
  }

  return (
    <Link
      type={type}
      href={content.slug ?? '/'}
      prefetch={!content.isNativeLink ? false : undefined}
      native={content.isNativeLink}
      id={content.elementId}
      className={elementClassNames}
      onClick={(mouseEvent: MouseEvent<HTMLAnchorElement>) => {
        trackLinkClick(mouseEvent, content);
        hideElements();
      }}
      onAuxClick={(mouseEvent: MouseEvent<HTMLAnchorElement>) =>
        trackLinkClick(mouseEvent, content)
      }
      {...getUrlProps({ openInNewTab: content.openLinkInNewTab, url: content.slug })}
    >
      {renderChildren()}
    </Link>
  );
};

export default RoundedButton;
