import classes from './Decoration.module.scss';

interface DecorationProps {
  className?: string;
}

const Decoration = ({ className }: DecorationProps) => (
  <div className={[classes.container, className].join(' ')}>
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Decoration;
