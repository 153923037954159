import Link from 'next/link';

import type { CaseStudiesList, CaseStudy } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import { BUTTON_SIZES } from '~/components/Buttons/RoundedButton/RoundedButton.constants';
import Image from '~/components/Image/Image';
import { getUploadAssetsURL } from '~/utils/functions';
import { getCaseStudyUrl } from '~/utils/urls';

import classes from './CaseStudyList.module.scss';

const CaseStudyList = ({ caseStudies }: CaseStudiesList) => {
  const renderCaseStudy = (caseStudy: CaseStudy) => {
    const url = getCaseStudyUrl(caseStudy);

    return (
      <div className={classes.CaseStudyList__singleArticle}>
        {caseStudy.image ? (
          <Link href={url} legacyBehavior>
            <a>
              <Image
                src={getUploadAssetsURL(caseStudy.image.url)}
                alt={caseStudy.title}
                height="120"
                width="248"
                layout="intrinsic"
              />
            </a>
          </Link>
        ) : null}
        <Link href={url} legacyBehavior>
          <a>
            <h2 className={classes.CaseStudyList__singleArticleTitle}>{caseStudy.title}</h2>
          </a>
        </Link>
        <div className={classes.CaseStudyList__singleArticleExcerpt}>{caseStudy.excerpt}</div>
        <div className={classes.CaseStudyList__singleArticleButtonHolder}>
          <RoundedButton
            content={{
              label: 'Read more',
              slug: url,
              backgroundColor: 'Yellow',
              withBorder: false,
              size: BUTTON_SIZES.REGULAR,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <section className={classes.CaseStudyList}>
      <div className={classes.CaseStudyList__inner}>{caseStudies.map(renderCaseStudy)}</div>
    </section>
  );
};

export default CaseStudyList;
