import classNames from 'classnames';
import propTypes from 'prop-types';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import {
  BUTTON_BG_COLORS,
  BUTTON_SIZES,
} from '~/components/Buttons/RoundedButton/RoundedButton.constants';
import Image from '~/components/Image/Image';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';

import classes from './ServicePerks.module.scss';

const ServicePerks = ({ content, className }) => {
  const numberOfColumns = content.perkCollection.length > 2 ? 3 : content.perkCollection.length;

  const containerClassNames = [
    className,
    classNames(
      classes.ServicePerks,
      classes[`${content.background}Background`],
      classes[`${content.variant}Icon`],
      classes[`${content.textAlign}Align`],
      numberOfColumns === 2 ? classes.twoColumns : '',
      numberOfColumns === 1 ? classes.singleColumn : '',
      {
        [classes.withBottomMargin]: content.withBottomMargin,
      },
    ),
  ].join(' ');

  // eslint-disable-next-line react/no-unstable-nested-components
  const PerkIcon = ({ icon }) => (
    <div className={classes.ServicePerks__PerkIcon}>
      {icon ? (
        <Image
          src={getUploadAssetsURL(icon.url)}
          alt={icon.alternativeText}
          height="29"
          width="29"
          unoptimized
        />
      ) : null}
    </div>
  );

  PerkIcon.propTypes = {
    icon: dataTypes.genericImage.props,
  };

  PerkIcon.defaultProps = {
    icon: dataTypes.genericImage.default,
  };

  const renderSinglePerk = (perkContent) => (
    <div className={classes.ServicePerks__singlePerk} key={perkContent.id}>
      <div className={classes.ServicePerks__singlePerkInner}>
        <PerkIcon icon={perkContent.icon} />
        <h4 className={classes.ServicePerks__singlePerkTitle}>{perkContent.title}</h4>
        <div
          className={classes.ServicePerks__singlePerkDescription}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(perkContent.description || '')}
        />
        {perkContent.buttonLabel && (
          <RoundedButton
            className={classes.ServicePerks__singlePerkButton}
            content={{
              label: perkContent.buttonLabel,
              slug: perkContent.buttonSlug,
              withBorder: true,
              size: BUTTON_SIZES.REGULAR,
              backgroundColor: BUTTON_BG_COLORS.DARK_PURPLE,
              event: perkContent.event,
              elementId: perkContent.elementId,
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <section className={containerClassNames}>
      <div className={classes.ServicePerks__inner}>
        <div className={classes.ServicePerks__row}>
          {content.perkCollection.map(renderSinglePerk)}
        </div>
      </div>
    </section>
  );
};

ServicePerks.propTypes = {
  content: dataTypes.servicePerks.props,
  className: propTypes.string,
};

ServicePerks.defaultProps = {
  content: dataTypes.servicePerks.default,
  className: '',
};

export default ServicePerks;
