import classnames from 'classnames';

import { createMarkup } from '../../../../utils/functions';
import { dataTypes } from '../../../../utils/propTypes';

import { CONTENT_TYPES } from './Content.constants';
import classes from './Content.module.scss';

const Content = ({ content, type, className = '', inline }) => {
  const elementClassNames = [
    className,
    classnames(classes.Content, {
      [classes[CONTENT_TYPES.REGULAR]]: type === CONTENT_TYPES.REGULAR,
      [classes[CONTENT_TYPES.FULL]]: type === CONTENT_TYPES.FULL,
    }),
  ].join(' ');

  return (
    <section className={elementClassNames}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={createMarkup(content, inline)} />
    </section>
  );
};

Content.propTypes = dataTypes.content.props;
Content.defaultProps = dataTypes.content.default;

export default Content;
