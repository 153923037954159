import Link from 'next/link';
import { createRef } from 'react';

import bugbugBrandLogoSVG from '../../public/assets/brand/bugbug-logotype-dark-purple.svg';
import { dataTypes } from '../../utils/propTypes';
import RoundedButton from '../Buttons/RoundedButton/RoundedButton';

import ContentMenu from './ContentMenu';
import classes from './MainNavBar.module.scss';
import MobileContentMenu from './MobileContentMenu';

const MainNavBar = ({ mainMenuItems, mobileMenuItems }) => {
  let menuRightButtons = mainMenuItems?.list.filter(
    // eslint-disable-next-line no-underscore-dangle
    (el) => el.__component === 'menu-components.menu-right-cta',
  );
  menuRightButtons = menuRightButtons.length ? Object.assign(...menuRightButtons) : null;

  const mobileButton = createRef();
  const mobileMenuContainer = createRef();

  const openMobileMenu = () => {
    mobileButton.current.classList.add(classes.isActive);
    mobileButton.current.setAttribute('aria-expanded', 'true');
    mobileMenuContainer.current.hidden = false;
    mobileMenuContainer.current.setAttribute('aria-hidden', 'false');
  };

  const closeMobileMenu = () => {
    mobileButton.current.classList.remove(classes.isActive);
    mobileButton.current.setAttribute('aria-expanded', 'false');
    mobileMenuContainer.current.hidden = true;
    mobileMenuContainer.current.setAttribute('aria-hidden', 'true');
  };

  const handleMobileButtonClick = () => {
    if (mobileButton.current.classList.contains(classes.isActive)) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  };

  const RenderMenuCTAsButtons = ({ buttons }) => {
    const renderSingleButton = (cta) => (
      <RoundedButton
        content={cta}
        key={`renderSingleButton-${cta.id}`}
        className={cta.elementClassname}
      />
    );
    return buttons?.singleCTAButton?.map(renderSingleButton) || null;
  };

  RenderMenuCTAsButtons.propTypes = {
    buttons: dataTypes.menuCTAsButtons.props,
  };

  RenderMenuCTAsButtons.defaultProps = {
    buttons: dataTypes.menuCTAsButtons.default,
  };

  return (
    <header className={classes.MainNavBar}>
      <div className={classes.MainNavBar__inner}>
        <div className={classes.MainNavBar__brandContainer}>
          <Link href="/" legacyBehavior>
            <a className={classes.MainNavBar__brandAnchor}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                className={classes.MainNavBar__brandImage}
                src={bugbugBrandLogoSVG.src}
                alt="Logotyp bugbug.io"
                width="110"
                height="34.5"
              />
            </a>
          </Link>
        </div>
        <div className={classes.MainNavBar__menuContainer}>
          <ContentMenu mainMenuItems={mainMenuItems} />
        </div>
        <div className={classes.MainNavBar__ctaContainer}>
          <RenderMenuCTAsButtons buttons={menuRightButtons} />
        </div>
        {mobileMenuItems && mobileMenuItems.list.length > 0 ? (
          <>
            <div className={classes.MainNavBar__mobileMenuButtonContainer}>
              <button
                ref={mobileButton}
                className={classes.MainNavBar__mobileMenuButton}
                aria-label="Menu"
                aria-haspopup="true"
                type="button"
                onClick={handleMobileButtonClick}
                aria-expanded="false"
              />
            </div>
            <div
              ref={mobileMenuContainer}
              className={classes.MainNavBar__mobileMenuContainer}
              hidden
              aria-hidden="true"
            >
              <MobileContentMenu
                mobileMenuItems={mobileMenuItems}
                closeMobileMenu={closeMobileMenu}
              />
              <div className={classes.MainNavBar__mobileMenuButtons}>
                <RenderMenuCTAsButtons buttons={menuRightButtons} />
              </div>
            </div>
          </>
        ) : (
          <div className={classes.MainNavBar__mobileSingleButtonMenu}>
            <RenderMenuCTAsButtons buttons={menuRightButtons} />
          </div>
        )}
      </div>
    </header>
  );
};

MainNavBar.propTypes = {
  mainMenuItems: dataTypes.mainMenuData.props,
  mobileMenuItems: dataTypes.mobileMenuData.props,
};

MainNavBar.defaultProps = {
  mainMenuItems: dataTypes.mainMenuData.default,
  mobileMenuItems: dataTypes.mobileMenuData.default,
};

export default MainNavBar;
