import type { ImageProps } from 'next/legacy/image';
import type { Testimonial } from '~/types/models';

import Image from '~/components/Image/Image';
import { getUploadAssetsURL } from '~/utils/functions';

import classes from './Testimonial.module.scss';

interface TestimonialAuthorProps {
  author?: Testimonial['author'];
  logo?: Testimonial['logo'];
  imageProps?: Partial<ImageProps>;
}

export const TestimonialAuthor = ({ author, logo, imageProps }: TestimonialAuthorProps) => (
  <div className={classes.author}>
    {author?.name && (
      <div className={classes.baseAuthorData}>
        <span>{author.name}</span>
        <span>{author.role}</span>
      </div>
    )}
    {logo?.url && (
      <div
        className={classes.logoContainer}
        style={{ aspectRatio: `${logo.width}/${logo.height}` }}
      >
        <Image
          className={classes.logo}
          loading="lazy"
          layout="fill"
          objectFit="contain"
          objectPosition="left"
          width={logo.width}
          height={logo.height}
          {...imageProps}
          src={getUploadAssetsURL(logo.url)}
          alt={logo.alternativeText}
        />
      </div>
    )}
  </div>
);
