import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';

import { isRouteMatchPattern } from '../Popup/Popup.helpers';
import { StickyBar } from '../StickyBar';

export function StickyBars() {
  const router = useRouter();
  const sharedData = useContext(SharedDataContext);

  const matchesAnyUrlFromList = (url: string, list = '') => {
    if (!list) {
      return false;
    }
    return list?.split('\n').some((pattern) => isRouteMatchPattern(pattern, url));
  };

  const stickyBarsOnThisPage = useMemo(
    () =>
      sharedData.stickyBars?.filter((bar) => {
        const path = router.asPath.replace(/\?.+/, '');
        if (matchesAnyUrlFromList(path, bar.excludedUrl)) {
          return false;
        }
        return matchesAnyUrlFromList(path, bar.includedUrl);
      }),
    [sharedData.stickyBars, router.asPath],
  );

  return stickyBarsOnThisPage?.map((bar) => <StickyBar key={bar.id} bar={bar} />);
}
