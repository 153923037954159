import { getUploadAssetsURL, createMarkup } from '../../../../utils/functions';
import { dataTypes } from '../../../../utils/propTypes';
import RoundedButton from '../../../Buttons/RoundedButton/RoundedButton';
import Image from '../../../Image/Image';

import classes from './Hero.module.scss';

// TODO: deprecated
const Hero = ({ data }) => (
  <section className={classes.PageHero}>
    <div className={classes.PageHero__inner}>
      <div className={classes.PageHero__content}>
        <h1 className={classes.PageHero__contentTitle}>{data.title}</h1>
        <div
          className={classes.PageHero__contentDescription}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(data.description)}
        />
        <div className={classes.PageHero__contentCTA}>
          {data.cta ? <RoundedButton content={data.cta} /> : null}
        </div>
      </div>
      <div className={classes.PageHero__media}>
        {data.heroImage ? (
          <Image
            src={getUploadAssetsURL(data.heroImage.url)}
            width="580"
            height="314"
            alt={data.heroImage.alternativeText}
            priority
          />
        ) : null}
      </div>
    </div>
  </section>
);

Hero.propTypes = dataTypes.pageHero.props;
Hero.defaultProps = dataTypes.pageHero.default;

export default Hero;
