import checkIcon from '~/public/assets/icons/check.svg';

import classes from './BottomSmallPerks.module.scss';

const BottomSmallPerks = ({ className, data = [] }) => {
  if (!data.length) {
    return null;
  }

  return (
    <div className={`${classes.bottomSmallPerks} ${className}`}>
      {data.map((perk) => (
        <div key={perk.replace(' ', '-')} className={classes.bottomSmallPerks__single}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={checkIcon.src} alt="check icon" />
          {perk}
        </div>
      ))}
    </div>
  );
};

export default BottomSmallPerks;
