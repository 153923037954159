import classnames from 'classnames';

import type { Author } from '~/types/models';

import { TYPE_OF_AUTHORS } from '../../../../utils/constants';
import Authors from '../../Authors/Authors';
import PostDate from '../PostDate/PostDate';

import classes from './PostMeta.module.scss';

interface PostMetaProps {
  className?: string;
  authors: Author[];
  date: string;
  type?: (typeof TYPE_OF_AUTHORS)[keyof typeof TYPE_OF_AUTHORS];
}

const PostMeta = ({
  className = '',
  authors = [],
  date = '',
  type = TYPE_OF_AUTHORS.STANDARD,
}: PostMetaProps) => {
  const elementClassNames = [
    classnames(
      classes.MetaPost,
      {
        [classes[TYPE_OF_AUTHORS.STANDARD]]: type === TYPE_OF_AUTHORS.STANDARD,
        [classes[TYPE_OF_AUTHORS.EXTENDED]]: type === TYPE_OF_AUTHORS.EXTENDED,
      },
      className,
    ),
  ].join(' ');

  return (
    <div className={elementClassNames}>
      {!!authors.length && (
        <>
          <Authors data={authors} type={type} />
          <span className={classes.MetaPost__divider}>&#8226;</span>
        </>
      )}
      <PostDate data={date} />
    </div>
  );
};

export default PostMeta;
