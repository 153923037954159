import Glide from '@glidejs/glide';
import { useRef, useEffect } from 'react';

import '@glidejs/glide/dist/css/glide.core.min.css';
import { dataTypes } from '../../../../utils/propTypes';

import classes from './SlideShow.module.scss';

const sliderConfiguration = {
  type: 'carousel',
  startAt: 0,
  animationTimingFunc: 'ease-in-out',
  gap: 100,
  perView: 1,
  autoplay: 5000,
};

const renderSingleSlideshowSlide = (singleSlide) => (
  <li className="glide__slide" key={`renderSingleSlideshowSlide-${singleSlide.id}`}>
    <div className={classes.SlideShow__slideInner}>
      <div className={classes.SlideShow__personName}>{singleSlide.personName}</div>
      <div className={classes.SlideShow__personRole}>{singleSlide.personRole}</div>
      <div className={classes.SlideShow__personSentence}>{singleSlide.personSentence}</div>
      <div className={classes.SlideShow__personScore}>
        <div
          className={classes.SlideShow__personVisualScore}
          style={{ width: `${singleSlide.personScore}%` }}
        />
      </div>
    </div>
  </li>
);

const SlideShow = ({ content }) => {
  const ref = useRef();

  useEffect(() => {
    const slider = new Glide(ref.current, sliderConfiguration);
    slider.mount();
  }, [ref]);

  return (
    <div className={classes.SlideShow}>
      <div className={classes.SlideShow__inner}>
        <div className={classes.SlideShow__decor}>
          <div className={classes.SlideShow__shapeOne} />
          <div className={classes.SlideShow__shapeTwo} />
          <div className={classes.SlideShow__shapeText} data-text="FEEDBACK" />
        </div>
        <div ref={ref} className={`glide ${classes.SlideShow__sliderContainer}`}>
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {content.singleOpinion.map(renderSingleSlideshowSlide)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

SlideShow.propTypes = {
  content: dataTypes.slideShow.props,
};

SlideShow.defaultProps = {
  content: dataTypes.slideShow.default,
};

export default SlideShow;
