import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { sendBackendRequest } from '../../../../../utils/api';

import { COUNTRY_LIST } from './BookDemoForm.constants';
import classes from './BookDemoForm.module.scss';

const formInputsSchema = yup.object().shape({
  name: yup.string('This field must be a string.').required('This field can not be empty.'),
  company: yup.string('This field must be a string.').required('This field can not be empty.'),
  country: yup.string('This field must be a string.').required('This field can not be empty.'),
  email: yup
    .string('This field must be a string.')
    .email('This field must contain a valid email.')
    .required('This field can not be empty.'),
  message: yup.string('This field must be a string.').required('This field can not be empty.'),
  contactConsent: yup
    .bool('You have to accept our privacy policy.')
    .oneOf([true], 'You have to accept our privacy policy.'),
});

const FORM_MESSAGES = {
  DEFAULT: 'Message was not send by server error. Please try again later.',
  SUCCESS: 'Message was send.',
  EMPTY: '',
};

const SUCCESS_MESSAGE_TIMEOUT = 3500;

const handleSubmitAction = async (values, actions) => {
  actions.setStatus({ submitting: true, msg: FORM_MESSAGES.EMPTY, show: false, isError: false });

  const emailObject = {
    replyTo: values.email,
    subject: 'https://bugbug.io book a demo form',
    text: `
    From : ${values.email} \n
    Name: ${values.name} \n
    Company: ${values.company} \n
    Country: ${values.country} \n
    Message: \n\n ${values.message} \n\n\n
    Send from https://bugbug.io \n\n
    `,
  };

  const [data, error] = await sendBackendRequest(
    {
      path: '/email/',
    },
    {
      method: 'POST',
      body: JSON.stringify(emailObject),
    },
  );

  if (error) {
    actions.setStatus({
      submitting: false,
      msg: FORM_MESSAGES.DEFAULT,
      show: true,
      isError: true,
    });
  }

  if (data) {
    actions.setStatus({
      submitting: false,
      msg: FORM_MESSAGES.SUCCESS,
      show: true,
      isError: false,
    });

    setTimeout(() => {
      actions.setStatus({
        submitting: false,
        msg: FORM_MESSAGES.EMPTY,
        show: false,
        isError: false,
      });
      actions.resetForm();
    }, SUCCESS_MESSAGE_TIMEOUT);
  }
};

const BookDemoForm = () => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
      company: '',
      country: 'Poland',
      contactConsent: false,
    }}
    validationSchema={formInputsSchema}
    onSubmit={handleSubmitAction}
  >
    {({ status }) => (
      <Form className={classes.BookDemoForm}>
        <div className={classes.BookDemoForm__fieldHolder}>
          <Field
            name="name"
            placeholder="Your Name"
            aria-label="Your Name"
            className={classes.BookDemoForm__textField}
          />
          <ErrorMessage
            name="name"
            className={classes.BookDemoForm__errorMessage}
            component="div"
          />
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          <Field
            name="email"
            type="email"
            placeholder="Your E-mail"
            aria-label="Your E-mail"
            className={classes.BookDemoForm__emailField}
          />
          <ErrorMessage
            name="email"
            className={classes.BookDemoForm__errorMessage}
            component="div"
          />
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          <Field
            name="company"
            placeholder="Your Company"
            aria-label="Your Company"
            className={classes.BookDemoForm__textField}
          />
          <ErrorMessage
            name="company"
            className={classes.BookDemoForm__errorMessage}
            component="div"
          />
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          <Field name="country" as="select" className={classes.BookDemoForm__selectField}>
            {Object.entries(COUNTRY_LIST).map((item) => {
              const [index, label] = item;
              return (
                <option key={index} value={label}>
                  {label}
                </option>
              );
            })}
          </Field>
          <ErrorMessage
            name="country"
            className={classes.BookDemoForm__errorMessage}
            component="div"
          />
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          <Field
            name="message"
            as="textarea"
            placeholder="How can we help?"
            aria-label="How can we help?"
            className={classes.BookDemoForm__textareaField}
          />
          <ErrorMessage
            name="message"
            className={classes.BookDemoForm__errorMessage}
            component="div"
          />
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="contactConsent" className={classes.BookDemoForm__label}>
            <div>
              <Field
                name="contactConsent"
                type="checkbox"
                id="contactConsent"
                aria-label="Privacy policy consent"
                className={classes.BookDemoForm__checkboxField}
              />
            </div>
            <div className={classes.BookDemoForm__labelCopy}>
              <span className={classes.BookDemoForm__checkboxFieldLabel}>
                By clicking &quot;Send Message&quot; you agree to our terms that you have read in
                our{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/privacy-policy/"
                  className={classes.BookDemoForm__checkboxFieldAnchor}
                >
                  Privacy Policy
                </a>{' '}
                page.
              </span>
              <ErrorMessage
                name="contactConsent"
                className={classes.BookDemoForm__errorMessageCheckbox}
                component="div"
              />
            </div>
          </label>
        </div>
        <div className={classes.BookDemoForm__fieldHolder}>
          <button
            className={classes.BookDemoForm__button}
            type="submit"
            disabled={status?.submitting || false}
          >
            <div
              className={classes.BookDemoForm__buttonSpinner}
              hidden={status?.submitting || false ? '' : 'hidden'}
            />
            <span>Send Message</span>
          </button>
          {status?.show ? (
            <div
              className={
                status?.isError
                  ? classes.BookDemoForm__formErrorMessage
                  : classes.BookDemoForm__formMessage
              }
            >
              {status.msg}
            </div>
          ) : null}
        </div>
      </Form>
    )}
  </Formik>
);

export default BookDemoForm;
