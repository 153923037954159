import { createContext } from 'react';

import type {
  Announcement,
  Article,
  ArticleCategory,
  Author,
  GeneralSettings,
  Popup,
  StickyBar,
} from '~/types/models';

export interface SharedData {
  settings: GeneralSettings;
  categories: ArticleCategory[];
  authors: Author[];
  recentPosts: Article[];
  popups: Popup[];
  stickyBars: StickyBar[];
  header: {
    // TODO: Create menu and menu items types
    menu: unknown;
    mobileMenu: unknown;
  };
  footer: {
    menu: unknown;
    recentPostsLabel: string;
    recentPosts: Article[];
    mostPopularPostsLabel: string;
    mostPopularPosts: Article[];
    footerText: string;
  };
  announcement?: Announcement;
}

export const SharedDataContext = createContext<SharedData>({
  settings: {
    id: '0',
    updatedAt: '',
    pageDomain: '',
    contactEmail: '',
    postsPerPage: 0,
    mostPopularPostsLabel: '',
    recentPostsNumber: 0,
    recentPostsLabel: '',
    footerText: '',
    globalSEO: [],
    extraComponents: [],
    cookieLawSentence: '',
    cssStyles: '',
    mostPopularPosts: [],
  },
  categories: [],
  authors: [],
  recentPosts: [],
  popups: [],
  stickyBars: [],
  header: {
    menu: {},
    mobileMenu: {},
  },
  footer: {
    menu: {},
    recentPostsLabel: '',
    recentPosts: [],
    mostPopularPostsLabel: '',
    mostPopularPosts: [],
    footerText: '',
  },
  announcement: {
    content: '',
    id: '',
    updatedAt: '',
  },
});

export const SharedDataProvider = SharedDataContext.Provider;
