/* eslint-disable react/no-danger */
import { useCallback } from 'react';

import { createMarkup } from '~/utils/functions';

import { dataTypes } from '../../../../utils/propTypes';

import classes from './FaqSection.module.scss';

const FaqSection = ({ content, options }) => {
  const IDS_TO_SHOW = options.questions.map((a) => a.id);
  const DATA_TO_SHOW = IDS_TO_SHOW.map((id) => content.find((c) => c.id === id));

  const closeFaqQuestion = useCallback((element) => {
    element.classList.remove(classes.faqExpanded);
  }, []);

  const openFaqQuestion = useCallback((element) => {
    const allButtons = document.querySelectorAll('[data-faq-id]');
    allButtons.forEach((singleButton) => {
      singleButton.classList.remove(classes.faqExpanded);
    });
    element.classList.add(classes.faqExpanded);
  }, []);

  const handleFaqQuestionClick = useCallback(
    (event) => {
      const clickedElement = event.currentTarget;

      if (clickedElement.classList.contains(classes.faqExpanded)) {
        closeFaqQuestion(clickedElement);
      } else {
        openFaqQuestion(clickedElement);
      }
    },
    [closeFaqQuestion, openFaqQuestion],
  );

  const renderSingleFaqQuestion = (section) => (
    <button
      className={classes.FaqSection__faqQuestionContainer}
      data-faq-id={section.id}
      key={`renderSingleFaqQuestion-${section.id}`}
      onClick={handleFaqQuestionClick}
      type="button"
    >
      <div className={classes.FaqSection__faqQuestion} id={section.urlAnchor}>
        {section.question}
        <span className={classes.FaqSection__faqContainerIcon} />
      </div>
      <div
        className={classes.FaqSection__faqAnswer}
        dangerouslySetInnerHTML={createMarkup(section.answer)}
      />
    </button>
  );

  const renderFaqGroup = (section) => (
    <div key={`renderFaqGroup-${section.id}`}>
      <div className={classes.FaqSection__faqLabel}>{section.groupLabel}</div>
      <div>{section?.faqQuestion.map(renderSingleFaqQuestion)}</div>
    </div>
  );
  return (
    <section className={classes.FaqSection}>
      <div className={classes.FaqSection__inner}>{DATA_TO_SHOW.map(renderFaqGroup)}</div>
    </section>
  );
};

FaqSection.propTypes = dataTypes.faqSection.props;
FaqSection.defaultProps = dataTypes.faqSection.default;

export default FaqSection;
