import classNames from 'classnames';
import { useRef } from 'react';

import type { GenericImage } from '~/types/models';

import { isVideoData } from '~/types/models';
import { getUploadAssetsURL } from '~/utils/functions';

import Video from '../Content/Video/Video';
import Image from '../Image/Image';

import classes from './Media.module.scss';

interface MediaProps {
  data: GenericImage | null;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Media = ({ data, className, style }: MediaProps) => {
  const mediaElementRef = useRef(null);

  if (!data) {
    return null;
  }

  const isVideo = isVideoData(data);
  const containerClassName = classNames(classes.container, className, {
    [classes.video]: isVideo,
  });

  const containerProps = { className: containerClassName, style };

  if (isVideo) {
    return (
      <div {...containerProps}>
        <Video data={data} ref={mediaElementRef} />
      </div>
    );
  }
  return (
    <div {...containerProps}>
      <Image
        src={getUploadAssetsURL(data.url)}
        width={data.width}
        height={data.height}
        alt={data.alternativeText}
      />
    </div>
  );
};

export default Media;
