import { dataTypes } from '../../../../utils/propTypes';

import classes from './PostDate.module.scss';

const PostDate = ({ data }) => {
  const publishDate = new Date(data);
  const publicationDay = publishDate.getDate();
  const publicationMonthLiteral = publishDate.toLocaleString('en', { month: 'long' });
  const publicationYear = publishDate.getFullYear();

  return (
    <span
      className={classes.Date}
    >{`${publicationMonthLiteral} ${publicationDay}, ${publicationYear}`}</span>
  );
};

PostDate.propTypes = {
  data: dataTypes.postsDate.props,
};

PostDate.defaultProps = {
  data: dataTypes.postsDate.default,
};

export default PostDate;
