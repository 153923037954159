import Balancer from 'react-wrap-balancer';

import { PopupTitle } from '~/types/models';

import SectionTitle from '../Content/Page/SectionTitle/SectionTitle';

import classes from './PopupTitle.module.scss';

interface PopupTitleProps {
  content: PopupTitle;
}

const PopupTitle = ({ content }: PopupTitleProps) => (
  <Balancer className={classes.wrapper}>
    <SectionTitle className={classes.title} content={{ title: content.title }} />
  </Balancer>
);

export default PopupTitle;
