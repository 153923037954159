import Link from 'next/link';

import type { Article, Menu } from '~/types/models';

import bugbugBrandLogoSVG from '../../public/assets/brand/bugbug-logotype-yellow.svg';
import NewsletterForm from '../Content/Page/Forms/Newsletter/NewsletterForm';

import BlogArticleList from './BlogArticleList/BlogArticleList';
import classes from './Footer.module.scss';
import MenuComponent from './Menu/Menu';
import Socials from './Socials/Socials';

interface FooterProps {
  data: {
    menu: Menu;
    recentPostsLabel: string;
    recentPosts: Article[];
    mostPopularPostsLabel: string;
    mostPopularPosts: Article[];
    footerText: string;
  };
}

const Footer = ({ data }: FooterProps) => {
  const {
    menu,
    recentPosts,
    recentPostsLabel,
    mostPopularPostsLabel,
    mostPopularPosts,
    footerText,
  } = data;
  return (
    <footer className={classes.Footer}>
      <div className={classes.Footer__inner}>
        <div className={classes.Footer__topContainer}>
          <div className={classes.Footer__claimContainer}>
            <Link href="/" legacyBehavior>
              <a className={classes.Footer__brandImageAnchor}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={bugbugBrandLogoSVG.src}
                  className={classes.Footer__brandImage}
                  alt="Logotyp bugbug.io"
                  width={145.5}
                  height={45.5}
                />
              </a>
            </Link>
            <p className={classes.Footer__claim}>{footerText}</p>
          </div>
          <div className={classes.Footer__menuContainer}>
            <MenuComponent menu={menu} />
          </div>
          <div className={classes.Footer__blogContainer}>
            <BlogArticleList posts={mostPopularPosts} label={mostPopularPostsLabel} />
            <BlogArticleList posts={recentPosts} label={recentPostsLabel} readMoreVisible />
          </div>
        </div>
        <div className={classes.Footer__bottomContainer}>
          <div className={classes.Footer__ctaContainer}>
            <div className={classes.Footer__ctaNewsletterLabel}>Sign up to our newsletter</div>
            <NewsletterForm buttonWithBorder elementClassname={menu.elementClassnameSubscribe} />
          </div>
          <div className={classes.Footer__socialContainer}>
            <Socials />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
