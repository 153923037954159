import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import type { ActiveCampaignForm as ActiveCampaignFormContent } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton';
import { usePopups } from '~/components/Popups/Popups.context';

import classes from './ActiveCampaignForm.module.scss';

interface ActiveCampaignFormProps {
  content: ActiveCampaignFormContent;
}

export const ActiveCampaignForm = ({
  content: { formId, button, fileToDownloadOnSubmit, closeActiveModalsOnSubmit },
}: ActiveCampaignFormProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { closeAll } = usePopups();

  // If the form is already loaded, the second invocation of useEffect in dev adds another form to the body element
  const loadedId = useRef<null | number>(null);

  useEffect(() => {
    if (loadedId.current === formId) return;

    const script = document.createElement('script');
    script.src = `https://bugbug.activehosted.com/f/embed.php?id=${formId}`;
    script.async = true;
    document.body.appendChild(script);
    loadedId.current = formId;

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script);
    };
  }, [formId]);

  useEffect(() => {
    if (!containerRef.current || !(fileToDownloadOnSubmit || closeActiveModalsOnSubmit)) return;

    const observer = new MutationObserver((records) => {
      const isSubmitResult = records.some((record) => {
        if (record.type !== 'childList') return false;
        if (!(record.target instanceof HTMLDivElement)) return false;
        return record.target.classList.contains('_form-thank-you');
      });

      if (!isSubmitResult) return;
      setHasSubmitted(true);

      if (closeActiveModalsOnSubmit) {
        closeAll();
      }

      if (fileToDownloadOnSubmit) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          window.location.href = fileToDownloadOnSubmit.url;
        } else {
          window.open(fileToDownloadOnSubmit.url, '_blank');
        }
      }
    });
    observer.observe(containerRef.current, { childList: true, subtree: true });

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [closeActiveModalsOnSubmit, closeAll, fileToDownloadOnSubmit]);

  const handleSubmitClick = () => {
    containerRef.current?.querySelector<HTMLButtonElement>("button[type='submit']")?.click();
  };

  return (
    <>
      <div
        ref={containerRef}
        className={classNames(`_form_${formId}`, classes.container, classes.disableNativeButton)}
      />
      {!hasSubmitted && (
        <RoundedButton content={button} type="button" onClick={handleSubmitClick} />
      )}
    </>
  );
};

interface ActiveCampaignFormRendererProps {
  formData: string | TrustedHTML;
}

export const ActiveCampaignFormRenderer = ({ formData }: ActiveCampaignFormRendererProps) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: formData }} className={classes.container} />
);
