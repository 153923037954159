import Link from 'next/link';

import { getUploadAssetsURL } from '~/utils/functions';
import { dataTypes } from '~/utils/propTypes';
import { getArticleUrl } from '~/utils/urls';

import Image from '../../../../Image/Image';
import PlaceholderImage from '../../../PlaceholderImage/PlaceholderImage';

import classes from './SidebarRecentArticle.module.scss';

const SidebarRecentArticle = ({ data }) => {
  const publishDate = new Date(data.publishDate);
  const publishFullDate = publishDate.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <article className={classes.SidebarRecentArticle}>
      <div className={classes.SidebarRecentArticle__inner}>
        <Link href={getArticleUrl(data)} legacyBehavior>
          <a className={classes.SidebarRecentArticle__linkWrapp} tabIndex="-1" aria-hidden="true">
            {data.title}
          </a>
        </Link>
        <div className={classes.SidebarRecentArticle__imageColumn}>
          <div className={classes.SidebarRecentArticle__imageContainer}>
            {data.featuredImage && data.featuredImage.url ? (
              <Image
                className={classes.SidebarRecentArticle__image}
                src={getUploadAssetsURL(data.featuredImage.url)}
                alt={data.featuredImage.alternativeText}
                width="85"
                height="69"
              />
            ) : (
              <PlaceholderImage
                width="85"
                height="69"
                cn={classes.SidebarRecentArticle__image}
                layout="intrinsic"
              />
            )}
          </div>
        </div>
        <div className={classes.SidebarRecentArticle__metaColumn}>
          <div className={classes.SidebarRecentArticle__title}>{data.title}</div>
          <div className={classes.SidebarRecentArticle__date}>{publishFullDate}</div>
        </div>
      </div>
    </article>
  );
};

SidebarRecentArticle.propTypes = {
  data: dataTypes.sidebarRecentArticle.props,
};

SidebarRecentArticle.defaultProps = {
  data: dataTypes.sidebarRecentArticle.default,
};

export default SidebarRecentArticle;
