import { dataTypes } from '../../../utils/propTypes';
import Image from '../../Image/Image';

const PlaceholderImage = ({ cn, width, height, layout }) => (
  <Image
    className={cn}
    src="/assets/blog/purpure-yellow-logotype-placeholder.png"
    width={width}
    height={height}
    layout={layout}
    alt="BugBug.io | Logotype on purple background"
  />
);

PlaceholderImage.propTypes = dataTypes.placeholderImage.props;
PlaceholderImage.defaultProps = dataTypes.placeholderImage.default;

export default PlaceholderImage;
