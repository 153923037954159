import React, { useMemo } from 'react';

import type { SharedData } from '~/contexts/sharedData';

import Announcement from '~/components/Announcement/Announcement';
import CookieConsent from '~/components/CookieConsent/CookieConsent';
import Popups from '~/components/Popups';
import { StickyBars } from '~/components/StickyBars';
import { SharedDataProvider } from '~/contexts/sharedData';
import { useTrackPageView } from '~/lib/mixpanel/mixpanel.hooks';

import Footer from '../../Footer/Footer';
import MainNavBar from '../../MainNavBar/MainNavBar';

interface MainPageFrameProps {
  children: React.ReactNode;
  sharedData: SharedData;
  trackEventPageName: string;
  customMenu?: {
    desktop?: unknown;
    mobile?: unknown;
  };
}

const MainPageFrame = ({
  children,
  customMenu,
  sharedData,
  trackEventPageName,
}: MainPageFrameProps) => {
  const { header, footer } = sharedData;

  const desktopMenu = useMemo(
    () => (customMenu?.desktop ? { list: customMenu.desktop } : header.menu),
    [customMenu, header],
  );

  const mobileMenu = useMemo(
    () => (customMenu?.mobile ? { list: customMenu.mobile } : header.mobileMenu),
    [customMenu, header],
  );

  useTrackPageView(trackEventPageName);

  return (
    <SharedDataProvider value={sharedData}>
      {sharedData.announcement && <Announcement {...sharedData.announcement} />}
      <MainNavBar
        // @ts-expect-error Remove after adding menu type
        mainMenuItems={desktopMenu}
        // @ts-expect-error Remove after adding menu type
        mobileMenuItems={mobileMenu}
      />
      <main>{children}</main>
      <Popups />
      <StickyBars />
      <CookieConsent globalSettings={sharedData.settings} />
      {/* @ts-expect-error Remove after adding menu type */}
      <Footer data={footer} />
    </SharedDataProvider>
  );
};

export default MainPageFrame;
