import { useMemo } from 'react';

import type { ActiveCampaignForm, PageContent } from '~/types/models';

interface PrefetchFormsProps {
  content: PageContent;
}

export const PrefetchForms = ({ content }: PrefetchFormsProps) => {
  const formIds = useMemo(
    () =>
      content
        .map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item.__component === 'page-components.active-campaign-form') {
            return (item as ActiveCampaignForm).formId;
          }
          return null;
        })
        .filter(Boolean) as number[],
    [content],
  );

  return formIds.map((formId) => (
    <link
      key={`${formId}-preload`}
      rel="preload"
      href={`https://bugbug.activehosted.com/f/embed.php?id=${formId}`}
      as="script"
    />
  ));
};
