import classNames from 'classnames';
import NextImage from 'next/legacy/image';

import type { ImageProps } from 'next/legacy/image';

import classes from './Image.module.scss';

// eslint-disable-next-line react/prop-types
const Image = ({ quality = 85, ...props }: ImageProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <NextImage {...props} className={classNames(classes.image, props.className)} quality={quality} />
);

export default Image;
