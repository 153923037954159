import type { CommonTestimonialSection, TestimonialSection } from '~/types/models';

import { createMarkup } from '~/utils/functions';

import classes from './Testimonial.module.scss';
import { TestimonialMark } from './TestimonialMark';

interface SimpleTestimonialProps extends Pick<CommonTestimonialSection, 'testimonial'> {
  className?: string;
}

export const SimpleTestimonial = ({ className, testimonial }: SimpleTestimonialProps) => {
  const renderQuote = (text: TestimonialSection['testimonial']['text']) => (
    // eslint-disable-next-line react/no-danger
    <div className={classes.quote} dangerouslySetInnerHTML={createMarkup(text, true)} />
  );

  return (
    <div className={className}>
      <div className={classes.content}>
        <div className={classes.quotationMark}>
          <TestimonialMark />
        </div>
        <div className={classes.texts}>
          {renderQuote(testimonial.text)}
          <div className={classes.author}>
            — {testimonial.author.name}, {testimonial.author.role}
          </div>
        </div>
      </div>
    </div>
  );
};
