/* eslint-disable @next/next/no-img-element */
import { useMemo } from 'react';

import type { TableCell } from '~/types/models';

import question from '~/public/assets/icons/action-check-condition.svg';
import check from '~/public/assets/icons/checkGreen.svg';
import close from '~/public/assets/icons/xRed.svg';
import { TableCompareSection } from '~/types/models';

import ExpandableSection from '../ExpandableSection';
import Help from '../Help';

import classes from './TableCompareSection.module.scss';

interface TableCompareSectionProps {
  content: TableCompareSection;
}

const TableCompareSection = ({ content }: TableCompareSectionProps) => {
  const rows = useMemo(
    () =>
      content.firstColumn.map((cell, index) => ({
        id: index,
        cells: [cell, content.secondColumn[index], content.thirdColumn[index]],
      })),
    [content],
  );
  const [headerRow, ...bodyRows] = rows;

  const renderIcon = (cell: TableCell) => (
    <>
      {cell.variant === 'checked' && <img src={check.src} alt={cell.variant} />}
      {cell.variant === 'unchecked' && <img src={close.src} alt={cell.variant} />}
      {cell.variant === 'unknown' && <img src={question.src} alt={cell.variant} />}
    </>
  );

  const renderHeaderCell = (cell?: TableCell) => (
    <th className={classes.cell}>{cell?.content ?? ''}</th>
  );

  const renderCell = (cell?: TableCell) => (
    <td className={classes.cell}>
      <p>
        {cell && renderIcon(cell)}
        <Help content={cell?.tooltip}>
          <span>{cell?.content ?? ''}</span>
        </Help>
      </p>
    </td>
  );

  return (
    <ExpandableSection
      title={content.title}
      contentClassName={classes.sectionContent}
      withMarginBottom={content.withMarginBottom}
      background="Transparent"
    >
      <table className={classes.table} cellSpacing="0" cellPadding="0">
        <thead>
          <tr>{headerRow.cells.map(renderHeaderCell)}</tr>
        </thead>
        <tbody>
          {bodyRows.map((row) => (
            <tr key={row.id}>{row.cells.map(renderCell)}</tr>
          ))}
        </tbody>
      </table>
    </ExpandableSection>
  );
};

export default TableCompareSection;
