import { createMarkup } from '~/utils/functions';

import { useCookieConsent } from './CookieConsent.context';
import classes from './CookieConsent.module.scss';

const CookieConsent = ({ globalSettings }) => {
  const { handleCookieConsentButton, isVisible } = useCookieConsent();

  return (
    <div className={classes.CookieConsent} hidden={!isVisible}>
      <div className={classes.CookieConsent__inner}>
        <span
          className={classes.CookieConsent__formula}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(globalSettings.cookieLawSentence, true)}
        />
        <button
          type="button"
          onClick={handleCookieConsentButton}
          className={classes.CookieConsent__button}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
