import Decoration from '~/components/Decoration/Decoration';
import Media from '~/components/Media/Media';
import { isVideoData } from '~/types/models';

import SignupForm from '../SignupForm/SignupForm';

import classes from './HeroWithForm.module.scss';

const HeroWithForm = ({ content, sharedData }) => {
  const { settings } = sharedData;
  const { title, kicker, signUpForm, description, image, media } = content;

  return (
    <section className={classes.hero}>
      <div className={classes.hero__inner}>
        <div className={classes.hero__content}>
          <header className={classes.hero__header}>
            <span>{kicker}</span>
            <h1 className="title">{title}</h1>
          </header>
          <p className={classes.hero__description}>{description}</p>
          {signUpForm && (
            <div className={classes.hero__formWrapper}>
              <SignupForm
                className={classes.hero__form}
                buttonText={signUpForm.buttonText}
                buttonUrl={signUpForm.buttonUrl}
                settings={settings}
                perks={signUpForm.perks}
                elementClassnameSignUp={signUpForm.elementClassnameSignUp}
              />
            </div>
          )}
        </div>
        <div className={classes.hero__mediaContainer}>
          <Media data={media ?? image} className={isVideoData(media) ? classes.hero__video : ''} />
          {image && !media && <Decoration />}
        </div>
      </div>
    </section>
  );
};

export default HeroWithForm;
