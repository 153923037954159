import NextLink from 'next/link';
import PropTypes from 'prop-types';

const Link = ({ native, ...linkProps }) => {
  if (native) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <a {...linkProps}>{linkProps.children}</a>;
  }

  const { href, prefetch, ...nativeLinkProps } = linkProps;
  return (
    <NextLink href={href} prefetch={prefetch} legacyBehavior>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <a {...nativeLinkProps}>{nativeLinkProps.children}</a>
    </NextLink>
  );
};

Link.propTypes = {
  native: PropTypes.bool,
};

Link.defaultProps = {
  native: false,
};

export default Link;
