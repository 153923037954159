export const BUTTON_BG_COLORS = {
  YELLOW: 'Yellow',
  TRANSPARENT: 'Transparent',
  DARK_PURPLE: 'DarkPurple',
  LIGHT_PURPLE: 'LightPurple',
};

export const BUTTON_SIZES = {
  REGULAR: 'Regular',
  BIG: 'Big',
  SMALL: 'Small',
};
