import classnames from 'classnames';
import Link from 'next/link';

import { dataTypes } from '../../../utils/propTypes';
import classes from '../Pagination.module.scss';

const PaginationButton = ({ show = true, label = '', current, disabled, url = '' }) => {
  let elementClasses = [
    classnames(classes.Pagination__listAnchor, classes.isDisabled, {
      [classes.isCurrent]: current,
    }),
  ].join(' ');

  // Standard version - not clickable element
  let paginationButtonContent = <span className={elementClasses}>{label}</span>;

  // Active version - contain proper URL and is not in disabled state
  if (url && !disabled) {
    elementClasses = [
      classnames(classes.Pagination__listAnchor, {
        [classes.isCurrent]: current,
      }),
    ].join(' ');

    paginationButtonContent = (
      <Link href={url} legacyBehavior>
        <a className={elementClasses}>{label}</a>
      </Link>
    );
  }

  return show ? (
    <li className={classes.Pagination__listElement}>{paginationButtonContent}</li>
  ) : null;
};

PaginationButton.propTypes = dataTypes.paginationArrow.props;
PaginationButton.defaultProps = dataTypes.paginationArrow.default;

export default PaginationButton;
