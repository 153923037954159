import classNames from 'classnames';

import { ContentTitle } from '~/types/models';
import { createMarkup } from '~/utils/functions';

import classes from './ContentTitle.module.scss';

interface ContentTitleProps {
  className?: string;
  content: ContentTitle;
}

const ContentTitle = ({ className, content }: ContentTitleProps) => {
  const { kicker, title, subtitle, withBottomMargin = true, variant = 'default' } = content;
  const elementClassNames = [
    classNames(
      classes.ContentTitle,
      {
        [classes.withBottomMargin]: withBottomMargin,
        [classes.condensed]: variant === 'condensed',
      },
      className,
    ),
  ].join(' ');

  return (
    <section className={elementClassNames}>
      <div className={classes.ContentTitle__inner}>
        {kicker && <div className={classes.ContentTitle__kicker}>{kicker}</div>}
        {title && <h1 className={classes.ContentTitle__title}>{title}</h1>}
        {subtitle && (
          <div
            className={classes.ContentTitle__subtitle}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(subtitle)}
          />
        )}
      </div>
    </section>
  );
};

export default ContentTitle;
