import dynamic from 'next/dynamic';

import type { SolutionPrimitive } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import Image from '~/components/Image/Image';
import { SolutionsSection } from '~/types/models';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';
import { getSolutionUrl } from '~/utils/urls';

import SectionTitle from '../SectionTitle/SectionTitle';

import classes from './SolutionsSection.module.scss';

interface SolutionsSectionProps extends SolutionsSection {
  content: SolutionsSection;
}

const SolutionsSection = ({ content }: SolutionsSectionProps) => {
  const renderSolution = (solutionItem: SolutionPrimitive) => {
    const url = getSolutionUrl(solutionItem.solution);

    return (
      <a className={classes.SolutionsSection__solution} key={solutionItem.id} href={url}>
        <div className={classes.SolutionsSection__solutionInner}>
          <div className={classes.SolutionsSection__solutionSymbolHolder}>
            {solutionItem.image ? (
              <Image
                src={getUploadAssetsURL(solutionItem.image.url)}
                alt={solutionItem.image.alternativeText}
                height="80"
                width="80"
                unoptimized
              />
            ) : null}
          </div>
          <div className={classes.SolutionsSection__solutionTitle}>{solutionItem.title}</div>
          <div className={classes.SolutionsSection__solutionDescription}>
            {solutionItem.description}
          </div>
          <div
            className={classes.SolutionsSection__solutionPerks}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(solutionItem.perks ?? '')}
          />
          <div className={classes.SolutionsSection__solutionCtaContainer}>
            {solutionItem.showMoreButton ? (
              <RoundedButton
                className={classes.SolutionsSection__solutionCtaButton}
                content={{
                  withBorder: true,
                  slug: url,
                  backgroundColor: 'LightPurple',
                  size: 'Regular',
                  label: 'See all benefits',
                  isNativeLink: solutionItem.solution.useNativeLink,
                }}
              />
            ) : null}
          </div>
        </div>
      </a>
    );
  };

  return (
    <section className={classes.SolutionsSection}>
      {content.title && (
        <SectionTitle content={{ title: content.title, backgroundColor: 'White' }} />
      )}
      <div className={classes.SolutionsSection__rows}>{content?.solutions.map(renderSolution)}</div>
    </section>
  );
};

export default dynamic(() => Promise.resolve(SolutionsSection), { ssr: false });
