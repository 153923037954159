import urlcat from 'urlcat';

import type {
  Article,
  CaseStudy,
  Integration,
  Solution,
  JobOffer,
  ArticleCategory,
  Author,
} from '~/types/models';

type GetCollectionItemUrl = <T extends { slug: string }>(
  collectionName: string,
) => (entry: T, paged?: string) => string;

export const getArticleUrl = (article: Article): string =>
  urlcat('/blog/:category/:slug/', {
    category: article.article_category.slug,
    slug: article.slug,
  });

export const getArticleUrlFromParams = ({
  categorySlug,
  articleSlug,
}: {
  categorySlug: string;
  articleSlug: string;
}): string =>
  urlcat('/blog/:categorySlug/:articleSlug/', {
    categorySlug,
    articleSlug,
  });

const getCollectionItemUrl: GetCollectionItemUrl = (collectionName) => (entry, paged) => {
  if (!entry) throw new Error(`Entry not found for collection ${collectionName}`);

  if (!paged) {
    return urlcat(`/${collectionName}/:slug/`, { slug: entry.slug });
  }

  return urlcat(`/${collectionName}/:slug/page/:paged`, { slug: entry.slug, paged });
};

export const getCaseStudyUrl = getCollectionItemUrl<CaseStudy>('case-study');

export const getSolutionUrl = getCollectionItemUrl<Solution>('solutions');

export const getIntegrationUrl = getCollectionItemUrl<Integration>('integrations');

export const getCareersUrl = getCollectionItemUrl<JobOffer>('careers');

export const getCategoryUrl = getCollectionItemUrl<ArticleCategory>('blog/category');

export const getAuthorUrl = getCollectionItemUrl<Author>('blog/author');
