import classNames from 'classnames';
import Image from 'next/image';
import Balancer from 'react-wrap-balancer';

import type { HeroCTASection as HeroCTASectionContent } from '~/types/models';
import type { PageData } from '~/types/page';

import RoundedButton from '~/components/Buttons/RoundedButton';
import { createMarkup, getUploadAssetsURL } from '~/utils/functions';

import classes from './HeroCTASection.module.scss';

interface HeroCTASectionProps {
  content: HeroCTASectionContent;
  pageData?: PageData;
}

export const HeroCTASection = ({
  content: { topImage, title, subtitle, description, background, button },
}: HeroCTASectionProps) => {
  const wrapperClassNames = classNames(classes.wrapper, classes[background ?? 'Transparent']);

  return (
    <div className={wrapperClassNames}>
      <div className={classes.content}>
        {topImage && (
          <div className={classes.imageContainer}>
            <Image
              className={classes.image}
              src={getUploadAssetsURL(topImage.url)}
              alt={topImage.alternativeText}
              fill
            />
          </div>
        )}
        {title && <h1 className={classes.title}>{title}</h1>}
        <div className={classes.details}>
          {subtitle && (
            <Balancer as="h2" className={classes.subtitle}>
              {subtitle}
            </Balancer>
          )}
          {description && (
            <div
              className={classes.description}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          )}
          <RoundedButton type="button" content={button} />
        </div>
      </div>
    </div>
  );
};
