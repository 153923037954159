import Link from 'next/link';

import SidebarTitle from '../SidebarTitle';

import classes from './LinkList.module.scss';

interface LinkListProps {
  title?: string;
  data: {
    name: string;
    url: string;
  }[];
}

const LinkList = ({ data: list, title }: LinkListProps) => (
  <div className={classes.container}>
    {title && <SidebarTitle content={{ title }} />}
    <div className={classes.linkHolder}>
      <ul className={classes.listContainer}>
        {list
          ? list.map((record) => {
              const { name, url } = record;
              return (
                <li className={classes.listElement} key={name}>
                  <Link href={url} prefetch={false} legacyBehavior>
                    <a>
                      <span className={classes.listLabel}>{name}</span>
                    </a>
                  </Link>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  </div>
);

export default LinkList;
