import * as Sentry from '@sentry/nextjs';
import initRetryFetch from 'fetch-retry';
import memoize from 'lodash/memoize';
import * as qs from 'qs';

import type { SharedData } from '~/contexts/sharedData';
import type { ArticleCategory, Author, GeneralSettings, Page } from '~/types/models';
import type { PageData } from '~/types/page';

import { imageMetaPayload, pageMetaPayload } from './metaPayloads';

const retryFetch = initRetryFetch(fetch, {
  retries: 3,
  retryDelay: 1000,
});

export async function sendBackendRequest(
  URL: { path?: string; payload?: Record<string, unknown> },
  options = {},
) {
  try {
    const defaultOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { path = '', payload = {} } = URL;
    payload.populate = payload.populate || '*';
    const fetchOptions = { ...defaultOptions, ...(options || {}) };
    const API_ENDPOINT = process.env.NEXT_PUBLIC_STRAPI_API_URL || '/api';
    const response = await retryFetch(
      `${API_ENDPOINT}${path}?${qs.stringify(payload, { encodeValuesOnly: true })}`,
      fetchOptions,
    );

    if (response.status === 200) {
      const body = await response.json();
      return [body.data, null, body.meta];
    }

    const error = new Error(`${response.url} HTTP:${response.status} - ${response.statusText}`);
    // @ts-expect-error - responseStatus is not a property of Error
    error.responseStatus = response.status;
    throw error;
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error);
    // @ts-expect-error - responseStatus is not a property of Error
    return [null, { error, status: error.responseStatus }];
  }
}

const getGlobalSettingsBase = () =>
  sendBackendRequest({
    path: '/global-setting',
    payload: {
      populate: {
        extraComponents: {
          populate: {
            sectionCTA: {
              populate: '*',
            },
          },
        },
        globalSEO: {
          populate: '*',
        },
        homepage: true,
        blogPage: true,
        blogCategoryPage: true,
        blogAuthorPage: true,
        mostPopularPosts: {
          fields: ['title', 'slug'],
          populate: {
            article_category: true,
            featuredImage: imageMetaPayload,
          },
        },
      },
    },
  });

export const getGlobalSettings =
  process.env.NODE_ENV === 'production' ? memoize(getGlobalSettingsBase) : getGlobalSettingsBase;

type GetBlogPage = (
  type: 'blog' | 'category' | 'author',
  pageNo: number,
  filters: Record<string, unknown>,
) => Promise<
  PageData & {
    content: Page;
  }
>;

export const getBlogPage: GetBlogPage = async (type, pageNo, filters = {}) => {
  const [globalSettings]: GeneralSettings[] = await getGlobalSettings();

  const postsPerPage = Number(globalSettings?.postsPerPage ?? 1);
  const articlesOffset = (+pageNo - 1) * postsPerPage;
  const isArchive = pageNo > 1;

  const [articles, , articlesMeta] = await sendBackendRequest({
    path: '/articles',
    payload: {
      filters,
      populate: {
        article_category: true,
        featuredImage: imageMetaPayload,
        authors: {
          fields: ['name', 'slug'],
        },
      },
      pagination: {
        start: articlesOffset,
        limit: postsPerPage,
      },
      sort: 'publishDate:desc',
    },
  });

  let templatePageSlug = globalSettings.blogPage?.slug;
  if (type === 'category' && globalSettings.blogCategoryPage) {
    templatePageSlug = globalSettings.blogCategoryPage.slug;
  }
  if (type === 'author' && globalSettings.blogAuthorPage) {
    templatePageSlug = globalSettings.blogAuthorPage?.slug;
  }

  const [[content]]: Page[][] = await sendBackendRequest({
    path: '/pages',
    payload: {
      populate: 'deep,6',
      filters: { slug: templatePageSlug },
    },
  });

  const page = Number(pageNo);

  return {
    articles,
    articlesPagination: {
      page,
      total: Number(articlesMeta.pagination.total ?? 0),
      postsPerPage: Number(postsPerPage),
    },
    content,
    isArchive,
    pageOutOfIndex: articles.length === 0 && page > 1,
    hasSidebar: isArchive
      ? !!content.sidebarForArchive?.content?.length
      : !!content.sidebar?.content?.length,
  };
};

export const getBlogCategoryPage = async (pageNo: number, slug: ArticleCategory['slug']) => {
  const [[categoryData]] = await sendBackendRequest({
    path: '/article-categories/',
    payload: {
      filters: {
        slug,
      },
      pagination: {
        start: 0,
        limit: 1,
      },
      sort: 'publishDate:desc',
    },
  });

  const result = await getBlogPage('category', pageNo, { article_category: categoryData.id });

  return {
    ...result,
    articlesPagination: {
      ...result.articlesPagination,
      total: categoryData.articles.length,
    },
    categoryData,
  };
};

export const getBlogAuthorPage = async (pageNo: number, authorSlug: Author['slug']) => {
  const [[authorData]] = await sendBackendRequest({
    path: '/authors/',
    payload: {
      filters: {
        slug: authorSlug,
      },
      pagination: {
        start: 0,
        limit: 1,
      },
    },
  });

  const result = await getBlogPage('author', pageNo, { authors: authorData.id });

  return {
    ...result,
    articlesPagination: {
      ...result.articlesPagination,
      total: authorData.articles.length,
    },
    authorData,
  };
};

export async function getSharedData() {
  const [globalSettings] = await getGlobalSettings();

  const menuPayload = {
    populate: {
      list: {
        populate: {
          dropdownLinks: {
            populate: {
              page: pageMetaPayload,
              event: true,
            },
          },
          event: true,
          page: pageMetaPayload,
          singleCTAButton: {
            populate: ['event'],
          },
        },
      },
    },
  };

  const [mainMenuData] = await sendBackendRequest({
    path: '/top-menu',
    payload: menuPayload,
  });

  const [mobileMenuData] = await sendBackendRequest({
    path: '/mobile-top-menu',
    payload: menuPayload,
  });

  const [announcement] = await sendBackendRequest({
    path: '/announcement',
  });

  const [footerMenuData] = await sendBackendRequest({
    path: '/footer-menu',
    payload: {
      populate: {
        list: {
          populate: {
            menuItems: {
              populate: {
                page: pageMetaPayload,
                event: true,
              },
            },
          },
        },
      },
    },
  });

  const [recentPosts] = await sendBackendRequest({
    path: '/articles',
    payload: {
      populate: {
        article_category: true,
        featuredImage: imageMetaPayload,
      },
      pagination: {
        start: 0,
        limit: globalSettings.recentPostsNumber,
      },
      sort: 'publishDate:desc',
    },
  });

  const [categories] = await sendBackendRequest({
    path: '/article-categories',
    payload: {
      fields: ['name', 'slug'],
      populate: {
        articles: {
          fields: ['id'],
        },
      },
      sort: 'name:asc',
    },
  });

  const [authors] = await sendBackendRequest({
    path: '/authors',
    payload: {
      populate: {
        image: imageMetaPayload,
        articles: {
          fields: ['id'],
        },
      },
      fields: ['name', 'role', 'slug'],
      sort: 'name:asc',
    },
  });

  const overlaysPayload = {
    populate: 'deep,4',
  };

  const [popups] = await sendBackendRequest({
    path: '/popups',
    payload: overlaysPayload,
  });

  const [stickyBars] = await sendBackendRequest({
    path: '/sticky-bars',
    payload: overlaysPayload,
  });

  const sharedData: SharedData = {
    settings: globalSettings,
    categories,
    recentPosts,
    authors,
    popups,
    stickyBars,
    announcement,
    header: {
      menu: mainMenuData,
      mobileMenu: mobileMenuData,
    },
    footer: {
      menu: footerMenuData,
      recentPosts,
      recentPostsLabel: globalSettings.recentPostsLabel,
      mostPopularPostsLabel: globalSettings.mostPopularPostsLabel,
      mostPopularPosts: globalSettings.mostPopularPosts,
      footerText: globalSettings.footerText,
    },
  };

  return sharedData;
}
