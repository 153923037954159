import type { FullWidthSection } from '~/types/models';

import RoundedButton from '~/components/Buttons/RoundedButton/RoundedButton';
import { createMarkup } from '~/utils/functions';

import classes from './FullWidthSection.module.scss';

const FullWidthSectionComponent = ({
  sectionContent,
  sectionDescription,
  sectionCTA,
  sectionBackground,
  withMarginBottom = true,
}: FullWidthSection) => (
  <section
    className={`${classes.FullWidthSection} ${classes[sectionBackground]} ${
      withMarginBottom ? '' : classes.noBottomMargin
    }`}
  >
    <div className={classes.FullWidthSection__inner}>
      <div className={classes.FullWidthSection__content}>
        <h3
          className={classes.FullWidthSection__contentTitle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(sectionContent || '', true)}
        />
        {sectionDescription && (
          <div
            className={classes.FullWidthSection__contentDescription}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={createMarkup(sectionDescription || '', true)}
          />
        )}
      </div>

      {sectionCTA.length ? (
        <div className={classes.FullWidthSection__ctaList}>
          {sectionCTA.map((button) => (
            <div className={classes.FullWidthSection__cta} key={button.id}>
              <div className={classes.FullWidthSection__ctaButtonWrapper}>
                <RoundedButton content={button} />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  </section>
);

export default FullWidthSectionComponent;
