import classes from './Socials.module.scss';

const PAGE_SOCIALS = [
  {
    id: 'Facebook',
    url: 'https://www.facebook.com/bugbugio',
    image:
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cdefs/%3E%3Cpath fill='%23401E58' d='M279.1 288l14.3-92.7h-89v-60c0-25.4 12.5-50.2 52.3-50.2H297V6.4S260.4 0 225.4 0C152 0 104.3 44.4 104.3 124.7v70.6H22.9V288h81.4v224h100.2V288z'/%3E%3C/svg%3E",
    altText: 'BugBug on Facebook',
  },
  {
    id: 'Linkedin',
    url: 'https://www.linkedin.com/company/bugbugio/',
    image:
      "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs/%3E%3Cpath fill='%23401E58' d='M416 32H31.9A32.1 32.1 0 000 64.3v383.4A32.1 32.1 0 0031.9 480H416c17.6 0 32-14.5 32-32.3V64.3A32.2 32.2 0 00416 32zM135.4 416H69V202.2h66.5V416zm-33.2-243a38.5 38.5 0 110-77 38.5 38.5 0 010 77zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z'/%3E%3C/svg%3E",
    altText: 'BugBug on Linkedin',
  },
];

const Socials = () => {
  const renderSocialAnchor = (sm) => (
    <li className={classes.FooterSocials__socialListElement} key={sm.id}>
      <a
        href={sm.url}
        className={classes.FooterSocials__socialListElementAnchor}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={sm.image}
          className={classes.FooterSocials__socialListElementIcon}
          alt={sm.altText}
          decoding="async"
          height="17"
          width="17"
          loading="lazy"
        />
      </a>
    </li>
  );

  return (
    <ul className={classes.FooterSocials__socialListContainer}>
      {PAGE_SOCIALS.map(renderSocialAnchor)}
    </ul>
  );
};

export default Socials;
